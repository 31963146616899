var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (
    _vm.project.dimensions != null &&
    _vm.project.dimensions.width != null &&
    _vm.project.dimensions.height != null
  )?_c('div',{staticStyle:{"user-select":"none !important"}},[_c('v-dialog',{staticStyle:{"user-select":"none"},attrs:{"width":800,"height":"90vh","persistent":_vm.project.measure == null},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('DialogTemplate',{staticClass:"elevation-1",attrs:{"closeable":_vm.project.measure != null,"title":"Maßstabeinstellungen","noHeader":true,"icon":"mdi-tape-measure"},on:{"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"mt-4",staticStyle:{"position":"relative","max-height":"calc(90vh - 135px)","overflow-y":"auto","overflow-x":"hidden"}},[(
              _vm.project != null &&
              _vm.project.files != null &&
              _vm.project.files.file != null
            )?_c('v-img',{staticClass:"image",style:(("aspect-ratio: " + (_vm.project.dimensions.width / _vm.project.dimensions.height))),attrs:{"preload":"","id":"fileImage","src":_vm.project.files.thumbnail},on:{"load":_vm.loadedImageEvent}},[_c('v-overlay',{attrs:{"color":"white","value":!_vm.loadedImage,"absolute":""}},[_c('v-progress-circular',{attrs:{"size":50,"color":"primary","indeterminate":""}})],1),_c('canvas',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadedImage),expression:"loadedImage"}],staticClass:"canvas-container",style:(("max-width: 768px; width: 768px; height: " + (768 * (_vm.project.dimensions.height / _vm.project.dimensions.width)) + "; cursor: " + (_vm.hasMeasurement ? 'default' : 'crosshair'))),attrs:{"id":"canvas"},on:{"mousemove":_vm.dragEvent,"mouseup":_vm.dragEndEvent,"mousedown":_vm.dragStartEvent}})],1):_vm._e()],1),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasMeasurement && _vm.loadedImage),expression:"hasMeasurement && loadedImage"}],staticClass:"clear-button mr-4",attrs:{"icon":"mdi-delete"},on:{"click":function($event){return _vm.clearCanvas()}}}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loadedImage),expression:"loadedImage"}],staticClass:"elevation-2 ml-4 settings-toolbar",style:(_vm.hasMeasurement == true
              ? 'border-radius: 20px;'
              : 'border-radius: 10px')},[(_vm.hasMeasurement == true)?_c('div',{on:{"click":function($event){_vm.hasChanged = true}}},[_c('TextInput',{attrs:{"suffix":"Meter","value":_vm.distanceValue,"placeholder":"","number":""},on:{"change":_vm.updatedDistanceValue}})],1):_c('div',{staticClass:"pl-5 pr-6 black--text font-weight-bold",staticStyle:{"font-size":"14px"}},[_c('v-row',{staticClass:"ma-0",staticStyle:{"height":"39px","user-select":"none"},attrs:{"align":"center","justify":"center"}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black","size":"18"}},[_vm._v("mdi-pencil")]),_vm._v(" Bitte eine Referenzdistanz mit der Maus ausmessen. ")],1)],1)])]},proxy:true},{key:"actions",fn:function(){return [_c('FurtherInfoAlert',{staticClass:"mt-1",attrs:{"text":"Die Maßstabsangaben dienen als Referenz für die Sichtfelder und -entfernungen der jeweiligen Kameras. Die Angaben müssen für eine aussagekräftige Projektierung akkurat sein.","link":"https://quick-bowl-4e0.notion.site/Projektierung-erstellen-d3d8f0b0cd3d4adfb74d1a7081acd351#985bd51f861247a9b12cc864816a000d"}}),_c('v-row',{staticClass:"ma-0 pb-1",attrs:{"justify":"end"}},[_c('TextButton',{directives:[{name:"show",rawName:"v-show",value:(_vm.project.measure != null),expression:"project.measure != null"}],attrs:{"icon":"mdi-close","text":"Schließen"},on:{"click":function($event){return _vm.close()}}}),_c('v-spacer'),_c('TextButton',{attrs:{"loading":_vm.loadingUpdate,"disabled":!_vm.hasMeasurement ||
              (!_vm.hasChanged && _vm.distanceValue != 0) ||
              _vm.distanceValue == null ||
              String(_vm.distanceValue).length == 0,"text":"Einstellung übernehmen","buttonColor":"primary"},on:{"click":function($event){return _vm.saveMeasurements()}}})],1)]},proxy:true}],null,false,3306968121)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }