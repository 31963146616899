




























































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { Container, ContainerSize } from "@/models/project/shapes";
import TextButton from "@components/button/TextButton.vue";
import TextInput from "@components/text/TextInput.vue";
import DetailsPanel from "../DetailsPanel.vue";

@Component({ components: { TextButton, TextInput, DetailsPanel } })
export default class ContainerOptions extends Vue {
  @Prop() container!: Container;

  sizes = ContainerSize;

  parseRot(value: any): number {
    var num = Number.parseFloat(value);
    while (num < 0) num += 180;
    while (num >= 180) num -= 180;
    return num;
  }

  setSize(value: ContainerSize): void {
    this.container.size = value;
    this.$emit("updated");
  }
  
  setRotation(value: any): void {
    this.container.rotation = this.parseRot(value);
    this.$emit("updated");
  }
}
