
























































































import { ProjectController } from "@/controllers/project_controller";
import IconButton from "@components/button/IconButton.vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { IconButton },
})
export default class AppBar extends Vue {

  @Prop()
  readonly controller!: ProjectController | null;
  
  @Prop()
  readonly isSync!: boolean;

  @Prop()
  readonly isReadonly!: boolean;

  get name(): string {
    return this.controller?.project.name ?? "";
  }

  goHome(): void {
    if (window.history.length > 1) {
      this.$router.back();
    } else {
      this.$router.replace("/");
    }
  }
}
