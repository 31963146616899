

import { ViewArc } from "@/models/project/camera";
import { Point } from "@/models/project/project";
import { CameraController } from "./camera_controller";
import { LineController } from "./line_controller";
import { angleTo, distanceTo, dot, edgeToLine, normalizeAngle, subtractPoints, translate } from "./point_controller";

export class ViewArcController {
 
  arc: ViewArc;

  parent: CameraController;
  slices: ViewSlice[];

  path: string;
  outOfBoundsPath: string;

  constructor(arc: ViewArc, parent: CameraController) {
    this.arc = arc;

    this.parent = parent;

    this.path = '';
    this.outOfBoundsPath = '';
    this.slices = [];

    this.reset();
  }

  get origin(): Point { return { ...this.parent.camera.origin }; }
  get aperture(): number { return this.mapZoom('aperture'); }
  get innerRadius(): number { return this.mapZoom('innerRadius'); }
  get outerRadius(): number { return this.mapZoom('outerRadius'); }

  mapZoom(key: 'aperture' | 'innerRadius' | 'outerRadius'): number {
    const start = this.arc.zoomStart[key];
    const end = this.arc.zoomEnd != null ? this.arc.zoomEnd[key] : null;
    if (end == null) return start;
    return start + this.arc.zoom * (end - start);
  }

  dragArc(p: Point): void {
    const a = angleTo(this.origin, p);
    this.arc.heading = a;

    if (this.arc.zoomEnd != null) {
      const d = distanceTo(this.origin, p);
      const minD = (this.arc.zoomStart.innerRadius + this.arc.zoomStart.outerRadius) / 2;
      const maxD = (this.arc.zoomEnd.innerRadius + this.arc.zoomEnd.outerRadius) / 2;

      const f = Math.max(Math.min((d - minD) / (maxD - minD), 1), 0);
      this.arc.zoom = f;
    }
  }

  reset(): void {
    if (this.aperture == 360) {
      this.slices = [
        new ArcViewSlice(0, 180, this),
        new ArcViewSlice(180, 360, this),
      ];
    } else {
      this.slices = [
        new ArcViewSlice(this.arc.heading - this.aperture/2, this.arc.heading + this.aperture/2, this),
      ];
    }
  }

  apply(line: LineController): void {
    const slices: ViewSlice[] = [];

    for (const slice of this.slices) {
      slices.push(...slice.apply(line));
    }

    this.slices = slices;
  }

  computePath(): void {

    let fullPath = '';
    let path = '';
    let innerStartPoint: Point | null = null;
    let startPoint: Point | null = null;
    let currPoint: Point | null = null;
    let startAngle = 0;
    let currAngle = 0;

    const closePath = () => {
      if (startPoint != null && innerStartPoint != null && currPoint != null) {
        if (distanceTo(startPoint, currPoint) < 1) {
          if (this.innerRadius > 0 && this.arc.zoomStart.aperture == 360 && currAngle - startAngle > 180) {
            path = `M${startPoint.x},${startPoint.y}` + path;
            const p1 = translate(this.origin, 0, this.innerRadius);
            const p2 = translate(this.origin, 180, this.innerRadius);
            path += `M${p1.x},${p1.y}`;
            path += `A${this.innerRadius},${this.innerRadius},0,0,0,${p2.x},${p2.y}`;
            path += `A${this.innerRadius},${this.innerRadius},0,0,0,${p1.x},${p1.y}Z`;
          } else {
            path = `M${startPoint.x},${startPoint.y}` + path;
            path += 'Z';
          }
        } else {
          path = `M${innerStartPoint.x},${innerStartPoint.y}` + path;
          const startAngle = angleTo(this.origin, innerStartPoint);
          currAngle = normalizeAngle(currAngle, startAngle);
          const p = translate(this.origin, currAngle, this.innerRadius);
          path += `L${p.x},${p.y}`;
          path += `A${this.innerRadius},${this.innerRadius},0,${currAngle-startAngle > 180 ? '1' : '0'},0,${innerStartPoint.x},${innerStartPoint.y}Z`;
        }
        startPoint = null;
        innerStartPoint = null;
        currPoint = null;
        fullPath += path;
        path = '';
      }
    }

    for (const slice of this.slices) {

      if (startPoint != null && currAngle != slice.startAngle) {
        closePath();
      }

      if (innerStartPoint == null) {
        innerStartPoint = translate(this.origin, slice.startAngle, this.innerRadius);
        startAngle = slice.startAngle;
      }

      if (slice instanceof ArcViewSlice) {
        const p1 = translate(this.origin, slice.startAngle, this.outerRadius);
        const p2 = translate(this.origin, slice.endAngle, this.outerRadius);
        path += `L${p1.x},${p1.y}A${this.outerRadius},${this.outerRadius},0,0,1,${p2.x},${p2.y}`;

        if (startPoint == null) {
          startPoint = p1;
        }
        currPoint = p2;
      } else if (slice instanceof CutViewSlice) {
        path += `L${slice.edge.start.x},${slice.edge.start.y}L${slice.edge.end.x},${slice.edge.end.y}`;
        if (startPoint == null) {
          startPoint = slice.edge.start;
        }
        currPoint = slice.edge.end;
      }

      currAngle = slice.endAngle;

    }

    if (startPoint != null) {
      closePath();
    }

    this.path = fullPath;
  }

  computeShadowPath(): void {

    let path = '';

    let pStart: Point | undefined;

    for (const slice of this.slices) {

      if (slice instanceof ArcViewSlice) {
        if (pStart == null) {
          continue;
        }

        const p1 = translate(this.origin, slice.startAngle, this.outerRadius);
        const p2 = translate(this.origin, slice.endAngle, this.outerRadius);
        path += `L${p1.x},${p1.y}A${this.outerRadius},${this.outerRadius},0,0,1,${p2.x},${p2.y}`;


      } else if (slice instanceof CutViewSlice) {

        if (pStart == null) {
          pStart = translate(this.origin, slice.startAngle, this.outerRadius);
          path += 'M';
        } else {
          path += 'L';
        }

        path += `${slice.edge.start.x},${slice.edge.start.y}L${slice.edge.end.x},${slice.edge.end.y}`;
      }

    }

    const slice = this.slices[this.slices.length - 1];
    const pEnd = translate(this.origin, slice.endAngle, this.outerRadius);

    if (pStart == null) {
      this.path = path;
      return;
    }

    path += `L${pEnd.x},${pEnd.y}`;
    path += `A${this.outerRadius},${this.outerRadius},0,0,0,${pStart.x},${pStart.y}`

    this.path = path + 'Z';

  }

}

export abstract class ViewSlice {
  startAngle: number;
  endAngle: number;
  controller: ViewArcController;

  constructor(startAngle: number, endAngle: number, controller: ViewArcController) {
    this.startAngle = startAngle;
    this.endAngle = normalizeAngle(endAngle, this.startAngle);
    this.controller = controller;
  }

  apply(line: LineController): ViewSlice[] {

    const edge1 = this.getStartEdge();
    const edge2 = this.getEndEdge();

    const cross1 = line.intersection(edge1);
    const cross2 = line.intersection(edge2);

    const points: { p: Point, a: number }[] = [];

    if (cross1 != null) {
      points.push({ p: cross1, a: this.startAngle });
    }
    if (cross2 != null) {
      points.push({ p: cross2, a: this.endAngle });
    }

    if (points.length < 2) {

      if (this.inArea(line.start)) {
        const angle = angleTo(this.controller.origin, line.start);
        points.push({ p: line.start, a: angle });
      }

      if (this.inArea(line.end)) {
        const angle = angleTo(this.controller.origin, line.end);
        points.push({ p: line.end, a: angle });
      }

    }

    if (points.length < 2) {

      for (const crossPoint of this.topIntersection(line)) {
        points.push({ p: crossPoint, a: angleTo(this.controller.origin, crossPoint) });
      }
    }

    if (points.length != 2) {
      return [this];
    }

    let p1 = points[0];
    let p2 = points[1];

    p1.a = normalizeAngle(p1.a, this.startAngle);
    p2.a = normalizeAngle(p2.a, this.startAngle);

    if (Math.abs(p1.a - p2.a) < Number.EPSILON * 1e10) {
      return [this];
    }

    if (p1.a > p2.a) {
      p1 = points[1];
      p2 = points[0];
    }

    const slices: ViewSlice[] = [];

    if (p1.a > this.startAngle) {
      try {
        slices.push(this.getStartSlice(p1.p, p1.a));
      } catch (e) {
        //noop
      }
    }

    if (p1.a < p2.a) {
      slices.push(new CutViewSlice(p1.a, p2.a, new LineController(p1.p, p2.p), this.controller));
    }

    if (p2.a < this.endAngle) {
      try {
        slices.push(this.getEndSlice(p2.p, p2.a));
      } catch (e) {
        //noop
      }
    }

    return this.applyInnerRadius(slices);
  }

  applyInnerRadius(slices: ViewSlice[]): ViewSlice[] {

    const resultSlices: ViewSlice[] = [];

    const innerRadius = this.controller.innerRadius;

    for (const slice of slices) {

      if (slice instanceof ArcViewSlice) {
        resultSlices.push(slice);
      } else if (slice instanceof CutViewSlice) {

        const startDist = distanceTo(this.controller.origin, slice.edge.start);
        const endDist = distanceTo(this.controller.origin, slice.edge.end);

        if (startDist <= innerRadius && endDist <= innerRadius) {
          continue;
        } else if (startDist >= innerRadius && endDist >= innerRadius) {
          const intersectPoints = this.arcIntersection(innerRadius, slice.edge);
          if (intersectPoints.length == 2) {
            let p1 = intersectPoints[0], p2 = intersectPoints[1];
            let a1 = normalizeAngle(angleTo(this.controller.origin, p1), this.startAngle);
            let a2 = normalizeAngle(angleTo(this.controller.origin, p2), this.startAngle);
            if (a1 > a2) {
              const p = p2;
              const a = a2;
              p2 = p1;
              p1 = p;
              a2 = a1;
              a1 = a;
            }
            resultSlices.push(new CutViewSlice(
              slice.startAngle, a1,
              new LineController(slice.edge.start, p1),
              slice.controller,
            ));
            resultSlices.push(new CutViewSlice(
              a2, slice.endAngle,
              new LineController(p2, slice.edge.end),
              slice.controller,
            ));
          } else {
            resultSlices.push(slice);
          }
        } else {
          const intersectPoints = this.arcIntersection(innerRadius, slice.edge);
          if (intersectPoints.length == 1) {
            const p = intersectPoints[0];
            const a = normalizeAngle(angleTo(this.controller.origin, p), this.startAngle);
            
            if (startDist < innerRadius) {
              resultSlices.push(new CutViewSlice(
                a, slice.endAngle,
                new LineController(p, slice.edge.end),
                slice.controller,
              ));
            } else {
              resultSlices.push(new CutViewSlice(
                slice.startAngle, a,
                new LineController(slice.edge.start, p),
                slice.controller,
              ));
            }
          }
        }
      }
    }

    return resultSlices;
  }

  abstract topIntersection(line: LineController): Point[];

  abstract getStartEdge(): LineController;
  abstract getEndEdge(): LineController;
  abstract inArea(p: Point): boolean;

  abstract getStartSlice(p: Point, a: number): ViewSlice;
  abstract getEndSlice(p: Point, a: number): ViewSlice;

  arcIntersection(radius: number, line: LineController): Point[] {

    const d = line.vector();
    const f = subtractPoints(line.start, this.controller.origin);
    const r = radius;

    const a = dot(d, d);
    const b = 2 * dot(f, d);
    const c = dot(f, f) - r * r;

    let discriminant = b * b - 4 * a * c;
    if (discriminant < 0) return [];

    discriminant = Math.sqrt(discriminant);

    const t1 = (-b - discriminant) / (2 * a);
    const t2 = (-b + discriminant) / (2 * a);

    const points: Point[] = [];

    if (t1 >= 0 && t1 <= 1) {
      const point = {
        x: line.start.x + d.x * t1,
        y: line.start.y + d.y * t1,
      };

      const angle = normalizeAngle(angleTo(this.controller.origin, point), this.startAngle);
      if (angle > this.startAngle && angle < this.endAngle) {
        points.push(point);
      }

    }

    if (t2 >= 0 && t2 <= 1) {
      const point = {
        x: line.start.x + d.x * t2,
        y: line.start.y + d.y * t2,
      };

      const angle = normalizeAngle(angleTo(this.controller.origin, point), this.startAngle);
      if (angle > this.startAngle && angle < this.endAngle) {
        points.push(point);
      }
    }

    return points;

  }
}

export class ArcViewSlice extends ViewSlice {
  constructor(startAngle: number, endAngle: number, controller: ViewArcController) {
    super(startAngle, endAngle, controller);
  }

  getStartEdge(): LineController {
    return edgeToLine(this.controller.origin, this.startAngle, this.controller.outerRadius)
  }

  getEndEdge(): LineController {
    return edgeToLine(this.controller.origin, this.endAngle, this.controller.outerRadius);
  }

  getStartSlice(p: Point, a: number): ViewSlice {
    return new ArcViewSlice(this.startAngle, a, this.controller);
  }

  getEndSlice(p: Point, a: number): ViewSlice {
    return new ArcViewSlice(a, this.endAngle, this.controller);
  }

  inArea(p: Point): boolean {
    const angle = normalizeAngle(angleTo(this.controller.origin, p), this.startAngle);
    const dist = distanceTo(this.controller.origin, p);
    return angle > this.startAngle && angle < this.endAngle && dist < this.controller.outerRadius;
  }

  topIntersection(line: LineController): Point[] {
    return this.arcIntersection(this.controller.outerRadius, line);
  }
}

export class CutViewSlice extends ViewSlice {

  edge: LineController

  constructor(startAngle: number, endAngle: number, edge: LineController, arc: ViewArcController) {
    super(startAngle, endAngle, arc);
    this.edge = edge;
  }

  getStartEdge(): LineController {
    return new LineController(this.controller.origin, this.edge.start);
  }

  getEndEdge(): LineController {
    return new LineController(this.controller.origin, this.edge.end);
  }

  getStartSlice(p: Point, a: number): ViewSlice {
    const outer = translate(this.controller.origin, a, this.controller.outerRadius);
    const cross = this.edge.intersection(new LineController(this.controller.origin, outer));
    if (cross == null) {
      console.error("START", this, p, a, outer, cross);
      throw Error("No Cross point for start slice");
    }
    return new CutViewSlice(this.startAngle, a, new LineController(this.edge.start, cross), this.controller);
  }

  getEndSlice(p: Point, a: number): ViewSlice {
    const outer = translate(this.controller.origin, a, this.controller.outerRadius);
    const cross = this.edge.intersection(new LineController(this.controller.origin, outer));
    if (cross == null) {
      console.error("END", this, p, a, outer, cross);
      throw Error("No Cross point for end slice");
    }
    return new CutViewSlice(a, this.endAngle, new LineController(cross, this.edge.end), this.controller);
  }

  inArea(p: Point): boolean {
    const angle = normalizeAngle(angleTo(this.controller.origin, p), this.startAngle);

    if (angle > this.startAngle && angle < this.endAngle) {
      const pEdge = new LineController(this.controller.origin, p);
      const cross = pEdge.intersection(this.edge);
      if (cross == null) {
        return true;
      }
    }
    return false;
  }


  topIntersection(line: LineController): Point[] {
    const p = line.intersection(this.edge);
    if (p != null) return [p];
    else return [];
  }
}
