

































































































































import TextButton from "@components/button/TextButton.vue";
import TextInput from "@components/text/TextInput.vue";
import IconButton from "@components/button/IconButton.vue";
import ProjectDialog from "@/components/dialogs/ProjectDialog.vue";
import { Project } from "@/models/project/project";
import Vue from "vue";

import { v4 as uuidv4 } from 'uuid';
import { Component } from "vue-property-decorator";


@Component({
  components: {
    IconButton, TextButton, ProjectDialog, TextInput
  },
})
export default class LoginView extends Vue {


  projects: Project[] = [];
  dialog = false;
  loadingCreation = false;
  searchValue = ''
  sortAscending = false;
  loadingProjects = true;



  async created(): Promise<void> {
    this.loadingProjects = true;
    this.projects = await this.$project.getProjectsForUser();
    this.loadingProjects = false;
  }


  get filteredProjects(): Project[] {
    if (this.projects == null) return [];
    var projectsHandler = this.projects;
    if (this.searchValue != null && this.searchValue.length > 0) {
      projectsHandler = projectsHandler.filter((project: Project) => project.name.toLowerCase().includes(this.searchValue.toLowerCase()))

    }

    if (this.sortAscending == true) return projectsHandler.sort((a, b) => b.updatedAt - a.updatedAt).reverse()
    else return projectsHandler.sort((a, b) => a.updatedAt - b.updatedAt).reverse()
  }



  openProject(project: Project): void {
    this.$router.push(`/project/${project.id}`);
  }

  async createProject(): Promise<void> {
    this.loadingCreation = true;
    let projectHandle: Project = {
      id: uuidv4(),
      name: '',

      info: {
        contact: { name: '', email: '', phone: '' },
        address: { street: '', postal: '', city: '' },
        descr: '',
        powerGrid: 0,
        offerId: '',
        projectAim: '',
        projectHints: '',
      },

      members: { [this.$firebase.auth.uid!]: { role: "owner" } },

      labels: [],
      cameras: [],
      shapes: [],
      statusLog: [],
      updatedAt: Date.now(),
      createdAt: Date.now(),
      createdBy: ''
    }

    try {
      projectHandle = await this.$project.createProject(projectHandle);

      setTimeout(() => {
        this.loadingCreation = false;
        this.$router.push(`/project/${projectHandle.id}`);
      }, 250);
    } catch {

      this.$toast.error('Projekt konnte nicht erstellt werden. Bitte erneut probieren.')
      this.loadingCreation = false;
    }

    // }
  }
  async logout(): Promise<void> {
    await this.$firebase.auth.logout();
    this.$router.push("/login");
  }
}
