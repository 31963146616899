





























































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";

import { Label, Project, StatusLog } from "@/models/project/project";
import { Camera } from "@/models/project/camera";
import { Shape } from "@/models/project/shapes";
import { CanvasTool } from "@/models/tools";

import ProjectBoard from "../ProjectBoard.vue";
import InfoToolbar from "./InfoToolbar.vue";

import ExportDialog from "@/components/dialogs/ExportDialog.vue";
import StatusChangeDialog from "@/components/dialogs/StatusChangeDialog.vue";

import ObjectDetails from "./ObjectDetails.vue";
import DetailsPanel from "./DetailsPanel.vue";

@Component({
  components: {
    ExportDialog,
    StatusChangeDialog,
    IconButton,
    TextButton,
    InfoToolbar,
    DetailsPanel,
    ObjectDetails,
  },
})
export default class InfoPanel extends Vue {
  endStatus = 0;
  loadingStatus = false;
  hoverDisabled = false;
  setBorderHandle = false;
  loadingDownload = false;
  loadingLink = false;

  @Prop() selected!: Camera | Shape | Label | null;
  @Prop() tool!: CanvasTool | null;
  @Prop() project!: Project;
  @Prop() board!: ProjectBoard;

  get status(): StatusLog {
    return this.statusLog[0];
  }

  get statusLog(): StatusLog[] {
    return [...this.project.statusLog].reverse();
  }

  @Watch("project", { immediate: true })
  onProjectUpdate(): void {
    if (this.loadingStatus == true) this.loadingStatus = false;
  }

  async resendReviewRequest(): Promise<void> {
    if (this.project == null || this.status == null) return;
    try {
      await this.$mail.sendStatusChangeMail(
        this.$firebase.auth.mail as string,
        "techn. Überprüfung angefordert (Erinnerung)",
        "Erinnerung: Bitte um technische Überprüfung der Projektierung",
        this.project as Project,
        `${document.location.toString()}`
      );
      this.$toast.info("Erinnerungsmail wurde erfolgreich gesendet.");
    } catch {
      this.$toast.error(
        "Erinnerungsmail konnte nicht gesendet weerden. Bitte nochmal probieren!"
      );
    }
  }

  setBorderModus(): void {
    this.setBorderHandle = !this.setBorderHandle;

    this.$emit("set-border", this.setBorderHandle);
  }

  get hasValidArmingCameras(): boolean {
    if (this.project == null || this.project.cameras == null) return false;

    return this.project.cameras
      .filter((cam) => cam.type.includes("ArmingCamera"))
      .every((cam) => cam.options?.mount != null);
  }

  async openExportDialog(status: number): Promise<void> {
    // if (this.selectedToolName != 'move') this.selectTool('move')

    if (
      this.board != null &&
      this.board.$refs != null &&
      this.board.$refs.canvas != null
    ) {
      (this.$refs.exportDialog as ExportDialog).open(status == 7);
      setTimeout(async () => {
        var snapshot = await (this.board.$refs.canvas as any).canvasSnapshot();

        (this.$refs.exportDialog as ExportDialog).snapshot = snapshot;
      }, 1000);
    }
  }

  async downloadLatestPlan(): Promise<void> {
    if (this.status.downloadUrl == null) return;
    this.loadingDownload = true;

    await this.$project.download("project", this.status.downloadUrl);
    this.loadingDownload = false;
  }

  async copyFileLink(): Promise<void> {
    if (this.status.downloadUrl == null) return;
    this.loadingLink = true;

    await this.$project.copy2Clipboard(this.status.downloadUrl as string);
    this.loadingLink = false;
  }

  async onChangeStatus(endStatus: number): Promise<void> {
    if (endStatus == 7) {
      this.$emit("fit");
      this.$nextTick(() => {
        this.openExportDialog(7);
      });
    } else if (this.status.status == 2) {
      this.$emit("fit");
      this.$nextTick(() => {
        this.openExportDialog(2);
      });
    } else {
      this.endStatus = endStatus;
      (this.$refs.statusChangeDialog as StatusChangeDialog).open(
        this.project ?? undefined
      );
    }

    // this.$project.updateProjectStatus(this.$route.params.projectId, 2, "test")
  }
}
