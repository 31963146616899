
























import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
// import { getStorage, ref, uploadBytes } from "firebase/storage";

@Component({
  components: { FurtherInfoAlert },
})
export default class FurtherInfoAlert extends Vue {
  @Prop() readonly text!: string;
  @Prop() readonly link!: any;
}
