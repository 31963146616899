import { Point } from "@/models/project/project";

interface FormalLine {
  dy: number;
  dx: number;
  c: number;
}

export class LineController {

  start: Point;
  end: Point;

  constructor(start: Point, end: Point) {
    this.start = start;
    this.end = end;
  }

  vector(): Point {
    return { x: this.end.x - this.start.x, y: this.end.y - this.start.y };
  }

  points(): number[] {
    return [this.start.x, this.start.y, this.end.x, this.end.y];
  }

  normal(): Point {
    return {
      x: this.start.y - this.end.y,
      y: this.end.x - this.start.x,
    };
  }

  toFormal(): FormalLine {

    const dy = this.end.y - this.start.y;
    const dx = this.end.x - this.start.x;
    const c = dy * this.start.x - dx * this.start.y;
    return { dy, dx, c };
  }

  intersection(other: LineController): Point | undefined {
    const l1 = this.toFormal();
    const l2 = other.toFormal();

    const det = l2.dy * l1.dx - l1.dy * l2.dx;
    if (det != 0) {

      const p = {
        x: (l1.dx * l2.c - l2.dx * l1.c) / det,
        y: (l1.dy * l2.c - l2.dy * l1.c) / det,
      };

      if (this.onLine(p, this, l1) && this.onLine(p, other, l2)) {
        return p;
      }
    }
  }

  onLine(p: Point, line: LineController, formal: FormalLine): boolean {
    let f = (p.x - line.start.x) * formal.dx + (p.y - line.start.y) * formal.dy;
    f /= formal.dx * formal.dx + formal.dy * formal.dy;

    return f >= 0 - Number.EPSILON * 1000 && f <= 1 + Number.EPSILON * 1000;
  }

  distanceTo(p: Point): number {
    const l = this.toFormal();

    const lsqr = l.dx * l.dx + l.dy * l.dy;
    if (lsqr == 0) {
      return Math.sqrt((p.x - this.start.x) * (p.x - this.start.x) + (p.y - this.start.y) * (p.y - this.start.y))
    }

    let t = ((p.x - this.start.x) * (l.dx) + (p.y - this.start.y) * l.dy) / lsqr;
    t = Math.max(0, Math.min(1, t));

    const pl = {
      x: this.start.x + t * l.dx,
      y: this.start.y + t * l.dy
    };

    return Math.sqrt((p.x - pl.x) * (p.x - pl.x) + (p.y - pl.y) * (p.y - pl.y));
  }

}