import _Vue from "vue";
import {
    getDocs,
    query,
    arrayUnion,
    deleteDoc,
    collection,
    where,
    FirestoreDataConverter,
    DocumentData,
    QueryDocumentSnapshot,
    SetOptions,
    SnapshotOptions,
    WithFieldValue,
    doc,
    getDoc,
    updateDoc,
    setDoc,
    onSnapshot,
} from "firebase/firestore";
import { firebaseFirestore } from "./firebase";
import { Project } from "@/models/project/project";
import Vue from "vue";

declare module "vue/types/vue" {
    export interface Vue {
        $project: ProjectPlugin;
    }
}

class ProjectPlugin {
    projectsCollection = collection(
        firebaseFirestore,
        "projects"
    ).withConverter(new ProjectConverter());

    static install(Vue: typeof _Vue) {
        Vue.prototype.$project = new ProjectPlugin();
    }

    _clipboardFallback(textString: string): void {
        const textArea = document.createElement("textarea");
        textArea.value = textString;
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.select();
        try {
            document.execCommand("copy");
        } catch (err) {
            console.error("Fallback: Oops, unable to copy", err);
        }
        document.body.removeChild(textArea);
    }

    async copy2Clipboard(dataStr: string): Promise<void> {
        let link = dataStr;
        const result = await Vue.prototype.$firebase.createLink(
            link,
            "test",
            "test"
        );

        if (result != null) {
            link = result;
        }

        if (!navigator.clipboard) {
            return this._clipboardFallback(link);
        }

        await navigator.clipboard.writeText(link);
    }

    async download(name: string, url: string): Promise<void> {
        await fetch(url)
            .then((res) => res.blob())
            .then((blob) => {
                const file = window.URL.createObjectURL(blob);

                const a = document.createElement("a");
                a.href = file;
                a.download = "alinotec_projektierungsentwurf.pdf";
                // a.download = `${name}.pdf`;
                a.click(); //Downloaded file
                a.remove();
                return true;
            })
            .catch((err) => {
                console.log(err);
                return false;
            });
    }

    async getProjectsForUser(): Promise<Project[]> {
        const snapshot = await getDocs(
            query(
                this.projectsCollection,
                where(
                    `members.${_Vue.prototype.$firebase.auth.uid}.role`,
                    "in",
                    ["owner", "editor", "reader", "reviewer"]
                )
            )
        );
        return snapshot.docs.map((doc) => doc.data());
    }

    async getProjectById(id: string): Promise<Project | undefined> {
        const snapshot = await getDoc(doc(this.projectsCollection, id));
        return snapshot.data();
    }

    watchProjectById(
        id: string,
        callback: (project: Project | undefined) => void
    ): void {
        onSnapshot(doc(this.projectsCollection, id), {
            next: (snapshot) => {
                callback(snapshot.data());
            },
        });
    }

    async createProject(project: Project): Promise<Project> {
        await setDoc(doc(this.projectsCollection, project.id), {
            ...project,
            statusLog: [],
            createdAt: Date.now(),
            createdBy: Vue.prototype.$firebase.auth.uid,
            updatedAt: Date.now(),
        });
        await this.updateProjectStatus(project.id, 1, "");
        return project;
    }

    async updateProject(project: Project): Promise<void> {
        const projectHandle = project;
        // projectHandle.updatedAt = Date.now();
        try {
            await setDoc(
                doc(this.projectsCollection, projectHandle.id),
                projectHandle
            );
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProject(projectId: string): Promise<void> {
        await deleteDoc(doc(this.projectsCollection, projectId));
    }

    async updateProjectStatus(
        projectId: string,
        status: number,
        comment: string | undefined,
        fileUrl?: string
    ): Promise<void> {
        await updateDoc(doc(this.projectsCollection, projectId), {
            statusLog: arrayUnion({
                status: status,
                comment: comment,
                downloadUrl: fileUrl || "",
                timestamp: Date.now(),
                mail: Vue.prototype.$firebase.auth.mail,
            }),
        });
    }
}

class ProjectConverter implements FirestoreDataConverter<Project> {
    toFirestore(project: Project): DocumentData {
        const json = { ...project } as any;
        delete json.id;
        return json;
    }

    fromFirestore(snapshot: QueryDocumentSnapshot<DocumentData>): Project {
        return { ...snapshot.data(), id: snapshot.id } as Project;
    }
}

_Vue.use(ProjectPlugin);
