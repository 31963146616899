var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"height":"100vh","width":"100vw","overflow":"hidden !important"},style:({ cursor: _vm.getToolCursor() })},[_c('v-stage',{ref:"stage",attrs:{"config":Object.assign({}, _vm.configKonva,
      {draggable: _vm.toolStageDraggable})},on:{"wheel":_vm.onScale,"dragmove":_vm.handleStageDrag,"mousemove":_vm.handleMouseMove,"mouseup":_vm.handleMouseUp,"mousedown":_vm.handleMouseDown}},[_c('v-layer',{ref:"background"},[_c('v-rect',{attrs:{"config":_vm.background}}),(_vm.planImage != null)?_c('v-rect',{attrs:{"config":{
          x: 0,
          y: 0,
          width: _vm.image.width,
          height: _vm.image.height,
          fillPatternImage: _vm.planImage,
          fillPatternOffset: { x: 0, y: 0 },
          fillPatternScale: {
            x: _vm.image.width / _vm.planImage.width,
            y: _vm.image.height / _vm.planImage.height,
          },
        }}}):_vm._e()],1),_c('v-layer',{ref:"shapes"},[_c('v-text',{attrs:{"config":{
          text: '50m',
          x: 0,
          y: -50,
          fontSize: 30,
        }}}),_c('v-line',{attrs:{"config":{
          x: 0,
          y: -15,
          points: [0, 0, 5 * 100, 0],
          stroke: 'black',
          strokeWidth: 7,
        }}}),_vm._l((_vm.shapes),function(shape){return [(shape.type == 'line')?_c('v-line',{key:shape.id,attrs:{"config":Object.assign({}, {x: 0,
            y: 0,
            points: shape.points},
            (shape.style || {}),
            {closed: shape.closed},
            (!shape.bounded ? { fill: null } : {}),
            {draggable: shape.draggable && _vm.toolShapeDraggable})},on:{"dragmove":function($event){return _vm.handleShapeDrag(shape, $event)},"click":function($event){return _vm.handleShapeClick(shape, $event)},"mouseenter":function($event){return _vm.handleShapeMouseEnter(shape, $event)},"mouseleave":function($event){return _vm.handleShapeMouseLeave(shape, $event)},"mousedown":function($event){return _vm.handleShapeMouseDown(shape, $event)}}}):_vm._e(),(shape.type == 'line' && !shape.bounded)?_c('v-shape',{key:shape.id + '-bounds',attrs:{"config":{
            x: _vm.background.x,
            y: _vm.background.y,
            width: _vm.background.width,
            height: _vm.background.height,
            sceneFunc: function (ctx, s) {
              _vm.clipFunc(ctx, shape.points, shape.bounded, false);
              ctx.fillStrokeShape(s);
            },
            fill: shape.style != null ? shape.style.fill : null,
          }}}):_vm._e(),(shape.type == 'clipped-path')?_c('v-group',{key:shape.id,attrs:{"config":{
            clipFunc: function (ctx) { return _vm.clipFunc(ctx, shape.points, shape.bounded, true); },
            opacity: 0.2,
          }}},[_c('v-path',{attrs:{"config":{
              data: shape.path,
              fillPatternImage: _vm.stripePatternImage,
              fillPatternScale: { x: 0.2, y: 0.2 },
            }}})],1):_vm._e(),(shape.type == 'path')?_c('v-path',{key:shape.id,attrs:{"config":Object.assign({}, {x: 0,
            y: 0,
            data: shape.path},
            (shape.style || {}),
            {draggable: _vm.toolShapeDraggable})},on:{"dragmove":function($event){return _vm.handleShapeDrag(shape, $event)},"click":function($event){return _vm.handleShapeClick(shape, $event)},"mouseenter":function($event){return _vm.handleShapeMouseEnter(shape, $event)},"mouseleave":function($event){return _vm.handleShapeMouseLeave(shape, $event)},"mousedown":function($event){return _vm.handleShapeMouseDown(shape, $event)}}}):_vm._e(),(shape.type == 'point')?_c('v-circle',{key:shape.id,attrs:{"config":Object.assign({}, {x: shape.point.x,
            y: shape.point.y,
            radius:
              (shape.radius || 5) / (shape.scale ? 1 : _vm.configKonva.scaleX)},
            (shape.style || {}),
            {draggable: shape.draggable && _vm.toolShapeDraggable})},on:{"dragmove":function($event){return _vm.handleShapeDrag(shape, $event)},"click":function($event){return _vm.handleShapeClick(shape, $event)},"mouseenter":function($event){return _vm.handleShapeMouseEnter(shape, $event)},"mouseleave":function($event){return _vm.handleShapeMouseLeave(shape, $event)},"mousedown":function($event){return _vm.handleShapeMouseDown(shape, $event)}}}):_vm._e(),(shape.type == 'circle')?_c('v-circle',{key:shape.id,attrs:{"config":{
            x: shape.point.x,
            y: shape.point.y,
            radius: shape.radius / (shape.scale ? 1 : _vm.configKonva.scaleX),
            fill: shape.color || 'white',
            stroke: '#00000033',
            strokeWidth: 5 / (shape.scale ? 1 : _vm.configKonva.scaleX),
            fillAfterStrokeEnabled: true,
          }}}):_vm._e(),(shape.type == 'circle' && shape.text != null)?_c('v-text',{key:shape.id + 'txt',attrs:{"config":{
            x:
              shape.point.x -
              shape.radius / (shape.scale ? 1 : _vm.configKonva.scaleX),
            y:
              shape.point.y -
              (shape.radius - 1) / (shape.scale ? 1 : _vm.configKonva.scaleX),
            width:
              (shape.radius * 2) / (shape.scale ? 1 : _vm.configKonva.scaleX),
            height:
              (shape.radius * 2) / (shape.scale ? 1 : _vm.configKonva.scaleX),
            text: shape.text,
            fontSize: 14 / (shape.scale ? 1 : _vm.configKonva.scaleX),
            fontFamily: 'Lato',
            fontStyle: 'bold',
            verticalAlign: 'middle',
            align: 'center',
            fill: shape.color != null ? 'white' : 'black',
          }}}):_vm._e(),(shape.type == 'text')?_c('v-text',{key:shape.id,attrs:{"config":{
            x: shape.position.x,
            y: shape.position.y,
            text: shape.text,
            fontSize: shape.fontSize,
            fontFamily: 'Lato',
            fontStyle: 'bold',
            align: 'left',
            fill: shape.color,
          }},on:{"mousedown":function($event){return _vm.handleShapeMouseDown(shape, $event)},"mouseenter":function($event){return _vm.handleShapeMouseEnter(shape, $event)},"mouseleave":function($event){return _vm.handleShapeMouseLeave(shape, $event)},"click":function($event){return _vm.handleShapeClick(shape, $event)}}}):_vm._e()]})],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }