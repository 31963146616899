





















import { addPoints, scale } from "@/controllers/point_controller";
import { CanvasTool } from "@/models/tools";
import { SelectTool, TextFocus } from "@/models/tools/select_tool";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {},
})
export default class ToolOverlay extends Vue {
  @Prop()
  readonly tool!: CanvasTool;

  textField: TextFocus | null = null;
  unwatch: (() => void) | null = null;

  @Watch("tool")
  onToolChanged(): void {
    if (this.tool instanceof SelectTool) {
      var t = this.tool;
      this.unwatch = this.$watch(
        () => t.focusedText,
        () => {
          if (t.focusedText != null) {
            var f = t.focusedText;
            this.textField = {
              ...f,
              padding: f.padding/2 * f.scale,
              position: addPoints(scale(addPoints(f.position, {x: f.padding/2, y: f.padding/2}), f.scale), f.offset),
              width: (f.width - f.padding) * f.scale,
              height: (f.height - f.padding) * f.scale,
              fontSize: f.fontSize * f.scale,
            };
            setTimeout(() => {
              (this.$refs.textarea as any).focus();
            })
          } else {
            this.textField = null;
          }
        },
        { deep: true, immediate: true }
      );
    } else {
      this.unwatch?.call(this);
      this.textField = null;
    }
  }

  onTextChanged(e: InputEvent): void {
    if (e.inputType == 'insertLineBreak') {
      (this.tool as SelectTool).finishEditing();
    } else {
      (this.tool as SelectTool).onFocusedTextEdited((e.target as any).value);
    }
  }
}
