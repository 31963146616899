









































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

import TextInput from "@components/text/TextInput.vue";

import { Label } from "@/models/project/project";
import DetailsPanel from "../DetailsPanel.vue";
import ColorSelector from "../ColorSelector.vue";

@Component({ components: { DetailsPanel, TextInput, ColorSelector } })
export default class LabelOptions extends Vue {
  @Prop() label!: Label;

  parseInt(value: any): number {
    return Number.parseInt(value);
  }

  setColor(value: string): void {
    this.$set(this.label, 'color', value);
  }

  setHighlightColor(value: string): void {
    this.$set(this.label, 'highlightColor', value);
  }
}
