






































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Tree } from "@/models/project/shapes";
import IconButton from "@components/button/IconButton.vue";
import DetailsPanel from "../DetailsPanel.vue";

@Component({ components: { IconButton, DetailsPanel } })
export default class TreeOptions extends Vue {
  @Prop() tree!: Tree;

  setRadius(value: number): void {
    this.tree.radius = value;
    this.$emit("updated");
  }
}
