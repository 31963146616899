






























































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";

import Vue from "vue";
import { Component } from "vue-property-decorator";
import DetailsPanel from "../DetailsPanel.vue";

@Component({
  components: { IconButton, TextButton, DetailsPanel },
})
export default class VideoTowerOptions extends Vue {
  deviceConfig = require("../../../../config.json");
}
