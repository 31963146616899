var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.textField != null)?_c('textarea',{ref:"textarea",staticClass:"edit-text",style:({
    top: (_vm.textField.position.y - _vm.textField.fontSize * 0.082) + 'px',
    left: _vm.textField.position.x + 'px',
    'font-size': _vm.textField.fontSize + 'px',
    width: _vm.textField.width+'px',
    height: _vm.textField.height+'px',
    padding: _vm.textField.padding+'px',
    color: _vm.textField.color,
  }),attrs:{"spellcheck":"false"},domProps:{"value":_vm.textField.text},on:{"input":_vm.onTextChanged}}):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }