import { Point } from "@/models/project/project";
import { Tree } from "@/models/project/shapes";
import { CanvasPoint, CanvasShape } from "@/models/shapes";
import { CameraController } from "./camera_controller";
import { LineController } from "./line_controller";
import { angleTo, distanceTo, translate } from "./point_controller";
import { ShapeController } from "./shape_controller";

export class TreeController implements ShapeController<Tree> {

  shape: Tree;

  constructor(shape: Tree) {
    this.shape = shape;
  }

  expell(p: Point): Point {
    const d = distanceTo(p, this.shape.origin);
    if (d <= this.shape.radius + 10) {
      const delta = this.shape.radius + 10 - d;
      p = translate(p, angleTo(p, this.shape.origin) + 180, delta);
    }
    return p;
  }

  contains(p: Point): boolean {
    return distanceTo(p, this.shape.origin) <= this.shape.radius;
  }

  applyTo(c: CameraController): void {
    const angle = angleTo(this.shape.origin, c.camera.origin);

    let curr = translate(this.shape.origin, angle - 90, this.shape.radius);

    const n = 10;
    const d = 180 / n;

    for (let i = 1; i <= n; i++) {
      const p = translate(this.shape.origin, angle - 90 + d * i, this.shape.radius);
      c.apply(new LineController(curr, p));
      curr = p;
    }
  }

  render(options: { id: string, draggable: boolean, focused: boolean }): CanvasShape<any>[] {
    const shapes = [] as CanvasShape<any>[];

    shapes.push(new CanvasPoint({
      id: `${options.id}-circ`,
      controller: this,
      point: this.shape.origin,
      draggable: options.draggable,
      priority: 5,
      radius: this.shape.radius,
      scale: true,
      style: {
        fill: '#008000cc',
        stroke: options.focused ? '#cc2027' : 'darkgreen',
        strokeWidth: options.draggable ? options.focused ? 3 : 0.5 : 2,
        strokeScaleEnabled: !options.focused,
      },
    }));

    if (options.draggable || options.focused) {
      shapes.push(new CanvasPoint({
        id: `${options.id}-pt`,
        controller: this,
        point: this.shape.origin,
        draggable: options.draggable,
        priority: 7,
        style: {
          fill: 'white',
          stroke: '#00000033',
          strokeWidth: 4,
          strokeScaleEnabled: false,
          fillAfterStrokeEnabled: true,
        },
      }));
    }

    return shapes;
  }
  
}