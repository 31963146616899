





































































































































































































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import TextInput from "@components/text/TextInput.vue";
import MapSelection from "../MapSelection.vue";
import { Project } from "@/models/project/project";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component({
  components: { MapSelection, TextButton, IconButton, TextInput },
})
export default class UploadProjectPlan extends Vue {
  searchingAddr = false;
  clearFileInput = false;
  loadingUpload = false;

  pixelPerMeter = 0;
  projectCoordinates = null;
  loadingProjectDelete = false;

  selectedFile: Blob | null = null;

  @Prop() project!: Project;

  get hasRequiredData(): boolean {
    if (
      this.project != null &&
      this.project.info.descr != null &&
      this.project.info.descr.length > 0 &&
      this.project.info.projectAim != null &&
      this.project.info.projectAim.length > 0 &&
      this.project.name != null &&
      this.project.name.length > 0 &&
      this.project.info.address != null &&
      this.project.info.address.street != null &&
      this.project.info.address.street.length > 0 &&
      this.project.info.address.postal != null &&
      this.project.info.address.postal.length > 0 &&
      this.project.info.address.city != null &&
      this.project.info.address.city.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  }

  upload(file: Blob): void {
    this.projectCoordinates = null;
    this.pixelPerMeter = 0;
    this.selectedFile = file;
  }

  async deleteProject(): Promise<void> {
    try {
      this.loadingProjectDelete = true;
      await this.$project.deleteProject(this.project.id as string);
      this.loadingProjectDelete = false;
    } catch (error) {
      this.$toast.error(
        "Projektdaten konnten nicht endgültig gelöscht werden."
      );
    }
  }

  async searchProjectAddr(): Promise<void> {
    this.searchingAddr = true;

    try {
      // geocode project adress to lat/lng coordinates
      const resp = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?address=${this.project.info.address.street},%20${this.project.info.address.postal}%20${this.project.info.address.city}&key=AIzaSyD-GfbKPxVjIVLs3c3vw_N1xor-eWdcrT8`
      ).then((resp) => resp.json());

      if (resp.status == "OK" && resp.results.length > 0) {
        this.selectedFile = null;
        this.clearFileInput = !this.clearFileInput;
        this.projectCoordinates = resp.results[0].geometry.location;
      } else {
        this.$toast.error(
          "Projektadresse nicht gefunden. Bitte die angegebenen Daten überprüfen."
        );
      }
    } catch (error) {
      this.$toast.error(
        "Fehler beim Laden der Adresse. Bitte erneut probieren."
      );
    }

    this.searchingAddr = false;
  }

  updateMapFile(fileHandle: any): void {
    if (this.projectCoordinates == null) return;

    if (
      this.projectCoordinates != null &&
      (fileHandle == null ||
        fileHandle.pixelPerMeter == null ||
        fileHandle.file == null)
    ) {
      this.selectedFile = null;

      this.pixelPerMeter = 0;
    } else {
      this.pixelPerMeter = fileHandle.pixelPerMeter;
      this.selectedFile = fileHandle.file;
    }
  }

  async save(): Promise<void> {
    if (this.selectedFile == null) return;
    this.loadingUpload = true;

    try {
      this.$toast.info("Projektplan wird hochgeladen...");
      const fileUrl = await this.$firebase.uploadFile(
        this.$route.params.projectId,
        "file",
        this.selectedFile
      );
      this.$toast.info("Projektplan wird verarbeitet...");

      this.project.files = { file: fileUrl };
      // this.$emit('save')

      // let projectHandle = this.project;

      if (
        this.projectCoordinates != null &&
        this.pixelPerMeter > 0 &&
        this.project != null
      ) {
        this.project.measure = {
          pixelPerMeter: this.pixelPerMeter,
        };
      }

      await this.$project.updateProject(this.project as Project);
    } catch {
      this.selectedFile = null;
    }

    this.loadingUpload = false;
  }
}
