import { CameraController } from "@/controllers/camera_controller";
import { PolygonController, PolygonPointController } from "@/controllers/polygon_controller";
import { CanvasShape, CanvasPoint } from "../shapes";
import { CanvasContext, CanvasTool, ShapeMouseEvent } from "../tools";
import { MoveTool } from "./move_tool";
import { SelectTool } from "./select_tool";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { Shape } from "../project/shapes";
import { TreeController } from "@/controllers/tree_controller";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";

export class BorderTool extends CanvasTool {
  onStageScale = MoveTool.scaleCanvas;
  onShapeDrag = SelectTool.dragShape;

  cursor = 'default';
  getCursor = (): string => this.cursor;

  onShapeMouseEnter = (e: ShapeMouseEvent): void => {
    if (e.shape instanceof CanvasPoint && e.shape.controller instanceof PolygonPointController) {
      if (!e.shape.controller.polygon.shape.bounded) {
        this.cursor = 'pointer';
      }
    }
  }

  onShapeMouseLeave = (): void => {
    this.cursor = 'default';
  }

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index,
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`,
        stroked: false,
        focused: !shape.shape.bounded,
        draggable: !shape.shape.bounded,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context});
  }
}