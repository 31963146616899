




















































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";

import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { TypesCameraTool } from "@/models/tools/draw_camera_tool";
import DetailsPanel from "../DetailsPanel.vue";

@Component({
  components: { IconButton, TextButton, DetailsPanel },
})
export default class DeviceOptions extends Vue {
  @Prop() readonly tool!: TypesCameraTool;

  get types(): string[] {
    return Object.keys(this.tool.types);
  }

  getImgUrl(device: string): void {
    return require(`../../../assets/devices/${device}.webp`);
  }
}
