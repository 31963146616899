import { CameraController } from "@/controllers/camera_controller";
import { distanceTo } from "@/controllers/point_controller";
import { PolygonController } from "@/controllers/polygon_controller";
import { CanvasShape, CanvasLine, CanvasPoint } from "../shapes";
import { CanvasContext, CanvasTool, StageMouseEvent } from "../tools";
import { MoveTool } from "./move_tool";

import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Point } from "../project/project";
import { Polygon, Shape, ShapeType } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";

export class DrawPolygonTool extends CanvasTool {
  onStageScale = MoveTool.scaleCanvas;

  points: Point[] = [];
  nextPoint: Point | null = null;
  willFinishShape = false;

  isShiftPressed = false;
  isAltPressed = false;
  isMouseDown = false;
  options = {};

  get hasUnbounded(): boolean {
    return this.controller.bounds != undefined;
  }

  get polygon(): Polygon {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const tool = this;
    const poly = { options: this.options };
    return new Proxy(poly, {
      get(target: any, p: string, receiver: any) {
        return target[p];
      },
      set(target: any, p: string | symbol, value: any, receiver: any): boolean {
        target[p] = value;
        if (p == 'options') {
          tool.options = value;
        }
        return true;
      },
    });
  }

  getCursor = (): string => this.isShiftPressed ? this.isMouseDown ? 'grabbing' : 'grab' : this.willFinishShape ? 'pointer' : 'crosshair';

  onKeyDown = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = true;
      this.onStageDrag = MoveTool.dragCanvas;
    }
    if (e.key.toLowerCase() == 'alt') {
      this.isAltPressed = true;
    }
  }

  onKeyUp = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = false;
      this.onStageDrag = null;
    }
    if (e.key.toLowerCase() == 'alt') {
      this.isAltPressed = false;
    }
  }

  onStageMouseDown = (): void => {
    this.isMouseDown = true;
  }

  finishShape(): void {
    this.controller.addShape({ type: ShapeType.polygon, points: this.points, bounded: this.hasUnbounded, options: this.options });
    this.points = [];
    this.willFinishShape = false;
    if (!this.isAltPressed) {
      this.selectNext("pointer", `p${this.controller.shapes.length - 1}`);
    }
  }

  onStageMouseMoved = (e: StageMouseEvent): void => {
    if (this.nextPoint == null) this.nextPoint = { x: 0, y: 0 };
    this.nextPoint.x = e.projectedPoint.x;
    this.nextPoint.y = e.projectedPoint.y;

    if (this.points.length > 0) {
      const lastPoint = this.points[this.points.length - 1];
      const firstPoint = this.points[0];
      if (distanceTo(lastPoint, this.nextPoint) < 30 || distanceTo(firstPoint, this.nextPoint) < 30) {
        this.willFinishShape = true;
      } else {
        this.willFinishShape = false;
      }
    } else {
      this.willFinishShape = false;
    }
  };

  onStageMouseUp = (e: StageMouseEvent): void => {
    this.isMouseDown = false;
    if (this.isShiftPressed) return;
    const p = e.projectedPoint;

    if (this.points.length > 0) {
      const lastPoint = this.points[this.points.length - 1];
      const firstPoint = this.points[0];
      if (distanceTo(lastPoint, p) < 30 || distanceTo(firstPoint, p) < 30) {
        this.finishShape();
        return;
      }
    }

    this.points.push({ ...e.projectedPoint });
    this.onStageMouseMoved(e);
  };

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index,
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`,
        stroked: false,
        focused: false,
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context });
  }

  renderCustomShapes = (): CanvasShape<any>[] => {
    const points = [...this.points];
    if (this.nextPoint != null) points.push(this.nextPoint);
    return [
      new CanvasLine({
        id: "add-poly",
        controller: null,
        points: points.flatMap((p) => [p.x, p.y]),
        priority: 10,
        closed: false,
        style: {
          stroke: '#cc2027',
          strokeWidth: 2,
          strokeScaleEnabled: false,
        },
      }),
      ...points.map((p, i) => new CanvasPoint({
        id: "add-poly-" + i,
        controller: null,
        point: p,
        style: {
          fill: 'white',
          stroke: '#00000033',
          strokeWidth: 4,
          strokeScaleEnabled: false,
          fillAfterStrokeEnabled: true,
        },
        priority: 11,
      })),
    ]
  }
}