<template>
  <v-app 
    oncontextmenu="return false;" class="application">
    <v-main style="background-color: #FCFCFC; height: 100vh;">
      
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
// import { EventBus, PROJECT_MANAGEMENT_EVENT } from '@/main.js'

export default {
  name: 'App',

}



</script>

<style scoped>

html, body {
  margin: 0;
  height: 100%;
  overflow: hidden
}

.application {
  background-color: var(--v-background-base);

  /* font-family: 'Lato', sans-serif!important; */
  overflow-y: hidden!important;
}
</style>

<style lang="scss">
//Chrome, Safari, Opera
    // body ::-webkit-scrollbar { width: 0 !important;  }  

    // //Firefox
    // body { overflow: -moz-scrollbars-none; -ms-overflow-style: none;}

    // //Internet Explorer, Edge
    // body { -ms-overflow-style: none; }
    
@import "./scss/variables.scss";
html, body {margin: 0; height: 100%; overflow: hidden!important; font-family: 'Lato'}
.application {
  // font-family: 'Lato';
    overflow: hidden!important;
   font-family: $body-font-family, sans-serif !important;
    .title { // To pin point specific classes of some components
       font-family: $body-font-family, sans-serif !important;
    }
 }


</style>
