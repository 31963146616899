
























































































































































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import TextInput from "@components/text/TextInput.vue";
import FurtherInfoAlert from "./FurtherInfoAlert.vue";
import DialogTemplate from "@components/dialog/DialogTemplate.vue";
import { Project } from "@/models/project/project";
import Vue from "vue";
import { Component, Watch, Prop } from "vue-property-decorator";
// import { getStorage, ref, uploadBytes } from "firebase/storage";

@Component({
  components: {
    DialogTemplate,
    TextButton,
    IconButton,
    TextInput,
    FurtherInfoAlert,
  },
})
export default class MeasureDialog extends Vue {
  @Prop() readonly project!: Project;

  dialog = false;

  hasChanged = false;
  hasMeasurement = false;
  isDrawing = false;
  loadedImage = false;
  loadingUpdate = false;

  distanceValue = 0;
  measureValue = 0;

  measureLines = {
    start: { x: 0, y: 0 },
    end: { x: 0, y: 0 },
  };

  canvas: any = null;
  ctx: any = null;

  @Watch("dialog", { immediate: true })
  onDialogChange(): void {
    this.loadingUpdate = false;

    if (
      this.project == null ||
      this.project.measure == null ||
      this.project.measure.reference == null
    ) {
      this.hasMeasurement = false;
      this.hasChanged = false;
      this.clearCanvas();
    } else {
      this.updateSettingsData();
    }
  }

  updateSettingsData(): void {
    if (this.dialog == true && this.canvas != null) {
      if (
        this.project != null &&
        this.project.measure != null &&
        this.project.measure.reference != null &&
        this.project.measure.reference.distance != null &&
        this.project.measure.pixelPerMeter != null
      ) {
        this.distanceValue = this.project.measure.reference.distance;
        this.measureValue = this.project.measure.pixelPerMeter;

        this.measureLines = {
          start: {
            x: this.project.measure.reference.start.x * this.canvas.width,
            y: this.project.measure.reference.start.y * this.canvas.height,
          },
          end: {
            x: this.project.measure.reference.end.x * this.canvas.width,
            y: this.project.measure.reference.end.y * this.canvas.height,
          },
        };

        this.hasMeasurement = true;
        this.drawDimensionLine();

        this.hasChanged = false;
      } else {
        this.hasMeasurement = false;
        this.hasChanged = false;
        this.clearCanvas();
      }
    }
  }

  updatedDistanceValue(eventHandle: number): void {
    if (eventHandle == null) return;

    this.distanceValue = eventHandle;
    this.hasChanged = true;

    this.calcDimensions();
  }

  rendered(): void {
    if (
      this.project == null ||
      this.project.dimensions == null ||
      this.project.dimensions.height == null ||
      this.project.dimensions.width == null
    )
      return;

    // update canvas
    this.canvas = document.getElementById("canvas"); //canvas, context, other vars etc
    this.ctx = (this.canvas as any).getContext("2d");

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    // update canvas size
    this.canvas.width = 768;
    this.canvas.height =
      768 * (this.project.dimensions.height / this.project.dimensions.width);

    if (
      this.project.measure == null ||
      this.project.measure.reference == null
    ) {
      this.hasMeasurement = false;
      this.hasChanged = false;
      this.distanceValue = 100;
      this.clearCanvas();
      this.loadedImage = true;
    } else {
      this.updateSettingsData();
      setTimeout(() => {
        this.loadedImage = true;
        this.calcDimensions();
        this.drawDimensionLine();
      }, 250);
    }
  }

  startRender(): void {
    requestAnimationFrame(this.rendered);
  }

  loadedImageEvent(): void {
    requestAnimationFrame(this.startRender);
  }

  async saveMeasurements(): Promise<void> {
    var project = this.project;

    const image = document.getElementById("fileImage")?.getBoundingClientRect();

    project.measure = {
      pixelPerMeter: 1 / this.measureValue,

      reference: {
        distance: this.distanceValue,
        start: {
          x: this.measureLines.start.x / this.canvas.width,
          y: this.measureLines.start.y / this.canvas.height,
        },
        end: {
          x: this.measureLines.end.x / this.canvas.width,
          y: this.measureLines.end.y / this.canvas.height,
        },
      },
    };

    this.loadingUpdate = true;
    await this.$project.updateProject({ ...project });

    await this.$project.updateProjectStatus(project.id, 2, "");

    this.$nextTick(() => {
      this.loadingUpdate = false;
      this.dialog = false;
    });
  }

  clearCanvas(): void {
    if (this.canvas == null || this.ctx == null) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.hasMeasurement = false;
    this.isDrawing = false;
  }

  drawDimensionLine(): void {
    if (!this.loadedImage) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.hasMeasurement = true;

    this.ctx.beginPath();
    this.ctx.moveTo(this.measureLines.start.x, this.measureLines.start.y);
    this.ctx.lineTo(this.measureLines.end.x, this.measureLines.end.y, 0);

    this.ctx.strokeStyle = "#000000";
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = "round";
    this.ctx.stroke();

    this.ctx.beginPath();
    this.ctx.fillStyle = "#000000"; //blue
    this.ctx.strokeStyle = "#000000";

    this.ctx.arc(
      this.measureLines.start.x,
      this.measureLines.start.y,
      3,
      0,
      2 * Math.PI
    );
    this.ctx.fill();
    this.ctx.stroke();

    this.ctx.beginPath();

    this.ctx.arc(
      this.measureLines.end.x,
      this.measureLines.end.y,
      3,
      0,
      2 * Math.PI
    );
    this.ctx.fill();
    this.ctx.stroke();

    this.hasMeasurement = true;
  }
  dragEvent(e: MouseEvent): void {
    if (this.hasMeasurement == true || this.isDrawing == false) return;

    if (
      this.ctx == null ||
      this.canvas == null ||
      this.measureLines.start == null ||
      this.measureLines.start.x == null
    )
      return;

    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

    this.ctx.beginPath();
    this.ctx.strokeStyle = "#cc2027";
    this.ctx.fillStyle = "#cc2027";
    this.ctx.strokeStyle = "#cc2027";
    this.ctx.lineWidth = 2;
    this.ctx.lineCap = "round";

    this.ctx.moveTo(this.measureLines.start.x, this.measureLines.start.y);
    this.ctx.lineTo(e.offsetX, e.offsetY, 0);
    this.ctx.stroke();

    this.ctx.beginPath();

    this.ctx.arc(
      this.measureLines.start.x,
      this.measureLines.start.y,
      3,
      0,
      2 * Math.PI
    );
    this.ctx.fill();
    this.ctx.stroke();
  }

  dragStartEvent(e: MouseEvent): void {
    if (this.isDrawing) return this.dragEndEvent(true, e);
    if (this.hasMeasurement == true) return;

    this.hasMeasurement = false;
    this.isDrawing = true;

    this.canvas = document.getElementById("canvas");
    this.ctx = (this.canvas as any).getContext("2d");
    this.measureLines.start = { x: e.offsetX, y: e.offsetY };

    this.$nextTick(() => {
      this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);

      this.ctx.beginPath();
      this.ctx.fillStyle = "#cc2027";
      this.ctx.strokeStyle = "#cc2027";

      this.ctx.arc(
        this.measureLines.start.x,
        this.measureLines.start.y,
        3,
        0,
        2 * Math.PI
      );

      this.ctx.fill();
      this.ctx.stroke();
    });
  }

  dragEndEvent(fire: boolean, e: MouseEvent): void {
    if (this.hasMeasurement == true || fire != true) return;
    if (
      Math.abs(this.measureLines.start.x - e.offsetX) < 10 &&
      Math.abs(this.measureLines.start.y - e.offsetY) < 10
    ) {
      return this.clearCanvas();
    }
    this.canvas.style.cursor = "default";
    this.isDrawing = false;
    this.measureLines.end = {
      x: e.offsetX,
      y: e.offsetY,
    };

    this.calcDimensions();
    this.hasChanged = true;
  }

  calcDimensions(): void {
    const image = document.getElementById("fileImage")?.getBoundingClientRect();
    this.canvas.width = image?.width;
    this.canvas.height = image?.height;
    this.drawDimensionLine();

    var lineLength = Math.sqrt(
      (this.measureLines.end.x - this.measureLines.start.x) *
        (this.measureLines.end.x - this.measureLines.start.x) +
        (this.measureLines.end.y - this.measureLines.start.y) *
          (this.measureLines.end.y - this.measureLines.start.y)
    );

    this.measureValue =
      (this.distanceValue / lineLength) *
      (this.canvas.width / this.project.dimensions!.width);
  }

  close(): void {
    this.dialog = false;
    this.loadingUpdate = false;
  }

  open(): Promise<Project | null> {
    this.dialog = true;

    return new Promise((resolve) => {
      var unwatch = this.$watch("dialog", (value) => {
        if (!value) {
          resolve(null);
          unwatch();
        }
      });
      this.$on("save", (value: Project) => {
        unwatch();
        resolve(value);
      });
    });
  }
}
