import { render, staticRenderFns } from "./OverviewView.vue?vue&type=template&id=aa0c27b0&scoped=true&"
import script from "./OverviewView.vue?vue&type=script&lang=ts&"
export * from "./OverviewView.vue?vue&type=script&lang=ts&"
import style0 from "./OverviewView.vue?vue&type=style&index=0&id=aa0c27b0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "aa0c27b0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VHover } from 'vuetify/lib/components/VHover';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VChip,VDivider,VHover,VIcon,VListItem,VListItemAction,VListItemContent,VListItemTitle,VProgressCircular,VRow,VSpacer,VVirtualScroll})
