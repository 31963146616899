













import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import ProjectDialog from "@/components/dialogs/ProjectDialog.vue";
import Canvas, { CanvasImage } from "@/components/Canvas.vue";
import Loading from "@/components/Loading.vue";
import { CanvasShape } from "@/models/shapes";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import { ProjectController } from "@/controllers/project_controller";
import { CanvasTool } from "@/models/tools";
import { metersToPixel } from "@/models/canvas";
import { SceneContext } from "konva/lib/Context";
import ToolOverlay from "./ToolOverlay.vue";

@Component({
  components: { Canvas, IconButton, ProjectDialog, TextButton, Loading, ToolOverlay },
})
export default class ProjectBoard extends Vue {
  @Prop()
  readonly controller!: ProjectController | null;

  @Prop()
  readonly selectedTool!: CanvasTool | null;

  canvas: Canvas | null = null;

  mounted(): void {
    this.canvas = this.$refs.canvas as Canvas | null;
  }

  @Watch("controller", { immediate: true })
  updateAll(): void {
    this.controller?.updateAll();
  }

  fitPlanToView(): void {
    (this.$refs.canvas as Canvas).fitImageToWindow();
  }

  get image(): CanvasImage | null {
    if (
      this.controller == null ||
      this.controller.project == null ||
      this.controller.project.files == null
    )
      return null;
    var url = this.controller?.project.files.image;

    if (
      url != null &&
      this.controller!.project.dimensions != null &&
      this.controller!.project.measure != null
    ) {
      const dim = this.controller!.project.dimensions;

      if (dim == null) return null;

      var ppm = this.controller.project.measure.pixelPerMeter || 1;
      return {
        url,
        width: metersToPixel(dim.width / ppm),
        height: metersToPixel(dim.height / ppm),
      };
    }
    return null;
  }

  get shapes(): CanvasShape<any>[] {
    const tool = this.selectedTool;
    if (this.controller == null || tool == null) return [];

    let shapes = [
      ...this.controller.cameras.flatMap(tool.renderCamera.bind(tool)),
      ...this.controller.shapes.flatMap(tool.renderShape.bind(tool)),
      ...this.controller.labels.flatMap((v, i) => tool.renderLabel(v, i, this.canvas ?? undefined)),
      ...tool.renderCustomShapes(),
    ];

    shapes.sort((a, b) => a.priority - b.priority);
    return shapes;
  }
}
