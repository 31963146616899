






















































































































import Vue from "vue";
import IconButton from "@components/button/IconButton.vue";
import { Component, Prop } from "vue-property-decorator";

interface StatusElement {
  status: number;
  comment: string;
  timestamp: Date;
  downloadUrl?: string;
  mail: string;
}

@Component({
  components: {
    IconButton,
  },
})
export default class TimelineElement extends Vue {
  @Prop() status!: StatusElement;
  @Prop() active!: boolean;
  showStatusComment = false;
  loadingDownload = false;
  loadingLink = false;

  async copy2Clipboard(): Promise<void> {
    if (this.status.downloadUrl == null) return;
    this.loadingLink = true;

    await this.$project.copy2Clipboard(this.status.downloadUrl as string);
    this.loadingLink = false;
  }
  async download(): Promise<void> {
    if (this.status.downloadUrl == null) return;

    this.loadingDownload = true;

    await this.$project.download("project", this.status.downloadUrl);
    this.loadingDownload = false;
  }
}
