import { CameraController } from "@/controllers/camera_controller";
import { PolygonController } from "@/controllers/polygon_controller";
import { CanvasShape } from "../shapes";
import { CanvasContext, CanvasTool, StageMouseEvent, StageScaleEvent } from "../tools";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Shape } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";

export class MoveTool extends CanvasTool {

  onStageDrag = MoveTool.dragCanvas;
  onStageScale = MoveTool.scaleCanvas;

  cursor = 'grab';
  getCursor = (): string => this.cursor;

  onStageMouseDown = (): void => {
    this.cursor = 'grabbing';
  }

  onStageMouseUp = (): void => {
    this.cursor = 'grab';
  }

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index, 
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`, 
        stroked: false, 
        focused: false, 
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context });
  }

  static dragCanvas(e: StageMouseEvent): void {
    e.canvas.updateBackground();
  }

  static scaleCanvas(e: StageScaleEvent): number {

    const scaleBy = 1.04;

    e.event.preventDefault();
    const oldScale = e.stage.scaleX();

    const pointer = e.stage.getPointerPosition();

    if (pointer == null) return oldScale;

    const mousePointTo = {
      x: (pointer.x - e.stage.x()) / oldScale,
      y: (pointer.y - e.stage.y()) / oldScale,
    };

    let newScale = e.event.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;

    if (newScale < e.canvas.minZoom) newScale = e.canvas.minZoom;
    if (newScale > e.canvas.maxZoom) newScale = e.canvas.maxZoom;

    e.canvas.configKonva.scaleX = newScale;
    e.canvas.configKonva.scaleY = newScale;

    const newPos = {
      x: pointer.x - mousePointTo.x * newScale,
      y: pointer.y - mousePointTo.y * newScale,
    };

    e.stage.position(newPos);
    e.canvas.updateBackground();

    return newScale;
  }

}


