
import { Camera } from "@/models/project/camera";
import { Project, Label } from "@/models/project/project";
import { Shape } from "@/models/project/shapes";
import Vue from "vue";
import { CameraController } from "./camera_controller";
import { LabelController } from "./label_controller";
import { PolygonController } from "./polygon_controller";
import { ShapeController } from "./shape_controller";

export class ProjectController {

  project: Project;
  cameras: CameraController[];
  shapes: ShapeController<Shape>[];
  labels: LabelController[];

  constructor(project: Project) {
    this.project = project;
    this.cameras = project.cameras.map((c) => new CameraController(c));
    this.shapes = project.shapes.map((c) => ShapeController.from(c));
    this.labels = project.labels?.map((l) => new LabelController(l)) ?? [];
  }

  get bounds(): PolygonController | undefined {
    return this.shapes.find((s) => s instanceof PolygonController && !s.shape.bounded) as PolygonController | undefined;
  }

  addCamera(camera: Camera): void {
    this.project.cameras.push(camera);
    this.cameras.push(new CameraController(camera));
  }

  addShape(shape: Shape): void {
    this.project.shapes.push(shape);
    this.shapes.push(ShapeController.from(shape));
    this.updateAll();
  }

  addLabel(label: Label): void {
    if (this.project.labels == null)
      Vue.set(this.project, 'labels', []);
    this.project.labels!.push(label);
    this.labels.push(new LabelController(label));
  }

  removeDisabledCameras(): void {
    for (let i = 0; i < this.project.cameras.length; i++) {
      if (!this.cameras[i].enabled) {
        this.project.cameras.splice(i, 1);
        this.cameras.splice(i, 1);
        i--;
      }
    }
  }

  removeShape(controller: ShapeController<Shape>): void {
    this.project.shapes.splice(this.project.shapes.indexOf(controller.shape), 1);
    this.shapes.splice(this.shapes.indexOf(controller), 1);
    this.updateAll();
  }

  removeCamera(controller: CameraController): void {
    this.project.cameras.splice(this.project.cameras.indexOf(controller.camera), 1);
    this.cameras.splice(this.cameras.indexOf(controller), 1);
  }

  removeLabel(controller: LabelController): void {
    this.project.labels?.splice(this.project.labels.indexOf(controller.label), 1);
    this.labels.splice(this.labels.indexOf(controller), 1);
  }

  clearEmptyLabels(): void {
    this.project.labels = this.project.labels?.filter((l) => l.text.length > 0);
    this.labels = this.project.labels?.map((l) => new LabelController(l)) ?? [];
  }

  clearCameras(): void {
    this.project.cameras = [];
    this.cameras = [];
  }

  updateAll(): void {
    for (const camera of this.cameras) {
      this.update(camera);
    }
  }

  update(camera: CameraController): void {
    camera.reset();

    camera.tryExpell(this.shapes);

    if (!camera.enabled) {
      return;
    }

    const bounds = this.bounds;

    for (const shape of this.shapes) {
      if (shape != bounds) {
        shape.applyTo(camera);
      }
    }

    camera.computePath();
    camera.controllers.forEach((a) => (a.outOfBoundsPath = a.path));

    bounds?.applyTo(camera);

    camera.computePath();
  }
}