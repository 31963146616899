<template>
    <v-checkbox :disabled="disabled" v-model="value" dense hide-details :ripple="false">
        <template v-slot:label>
            <span :class=" value ? 'primary--text font-weight-bold' : 'black--text'" style="font-size: 15px">
            {{text}}
            </span>
        </template>
    </v-checkbox>

</template>

<script>

export default {
    props: {
        text: {
            type: String, 
            required: true
        }, 
        disabled: {
            type: Boolean, 
            default: false
        },
        src: {
            type: Boolean, 
            default: false
        }
    },

    data() {
        return {
            value: this.src
        }
    }, 

    watch: {
        
        value() {
            this.$emit('update', this.value)
        },
    }
}

</script>