/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { LabelController } from "@/controllers/label_controller";
import { Point } from "./project/project";

export interface ShapeStyle {
  stroke?: string;
  strokeWidth?: number;
  strokeScaleEnabled?: boolean;
  lineCap?: string;
  lineJoin?: string;
  dash?: number[];
  fill?: string;
  fillAfterStrokeEnabled?: boolean;
  shadowColor?: string;
  shadowBlur?: number;
}

export class CanvasShape<T> {

  id: string;
  controller: T;
  priority: number;
  type: string;
  style?: ShapeStyle | null;

  constructor(id: string, controller: T, priority: number, style: ShapeStyle | null , type: string) {
    this.id = id;
    this.controller = controller;
    this.priority = priority;
    this.type = type;
    this.style = style;
  }
}
export class CanvasPoint<T> extends CanvasShape<T> {
  
  point: Point;
  draggable: boolean;
  radius?: number;
  scale: boolean;

  constructor(options: {id: string, controller: T, point: Point, draggable?: boolean, radius?: number, style?: ShapeStyle, priority: number, scale?: boolean}) {
    super(options.id, options.controller, options.priority, options.style ?? null, 'point');
    this.point = options.point;
    this.radius = options.radius;
    this.draggable = options.draggable ?? false;
    this.scale = options.scale ?? false;
  }
}
export class CanvasCircle extends CanvasShape<null> {
  
  point: Point;
  text: string;
  color?: string;
  radius: number;
  scale: boolean;

  constructor(options: {id: string, point: Point, text: string, color?: string, priority: number, radius?: number, scale?: boolean}) {
    super(options.id, null, options.priority, null, 'circle');
    this.point = options.point;
    this.text = options.text;
    this.color = options.color;
    this.radius = options.radius ?? 10;
    this.scale = options.scale ?? false;
  }
}

export class CanvasLine<T> extends CanvasShape<T> {

  points: number[];
  closed: boolean;
  bounded: boolean;
  draggable: boolean;

  constructor(options: {id: string, controller: T, points: number[], priority: number, closed?: boolean, bounded?: boolean, draggable?: boolean, style?: ShapeStyle}) {
    super(options.id, options.controller, options.priority, options.style ?? null, 'line');
    this.points = options.points;
    this.closed = options.closed ?? false;
    this.bounded = options.bounded ?? true;
    this.draggable = options.draggable ?? false;
  }
}

export class CanvasPath<T> extends CanvasShape<T> {

  path: string;

  constructor(options: {id: string, controller: T, path: string, priority: number, style?: ShapeStyle}) {
    super(options.id, options.controller, options.priority, options.style ?? null, 'path');
    this.path = options.path;
  }
}
export class CanvasClippedPath extends CanvasShape<null> {

  path: string;
  points: number[];
  bounded: boolean;

  constructor(options: {id: string, path: string, points: number[], bounded: boolean, priority: number}) {
    super(options.id, null, options.priority, null, 'clipped-path');
    this.path = options.path;
    this.points = options.points;
    this.bounded = options.bounded;
  }
}

export class CanvasText extends CanvasShape<LabelController> {

  text: string;
  fontSize: number;
  position: Point;
  color: string;

  constructor(options: {id: string, controller: LabelController, text: string, fontSize: number, color: string, position: Point, priority: number}) {
    super(options.id, options.controller, options.priority, null, 'text');
    this.text = options.text;
    this.fontSize = options.fontSize;
    this.position = options.position;
    this.color = options.color;
  }  
  
}