import _Vue from 'vue';

import { initializeApp } from "firebase/app";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, onAuthStateChanged, User, signOut } from "firebase/auth";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";

import { getFirestore, } from "firebase/firestore";

import { FirebaseDynamicLinks } from 'firebase-dynamic-links';
declare module 'vue/types/vue' {
  export interface Vue {
    $firebase: FirebasePlugin
  }
}

const firebaseConfig = {
  apiKey: "AIzaSyCTccChwfSsj3aDQVE23MyaWlcWYyqIbuw",
  authDomain: "alinoplan-dev.firebaseapp.com",
  projectId: "alinoplan-dev",
  storageBucket: "alinoplan-dev.appspot.com",
  messagingSenderId: "1076823609591",
  appId: "1:1076823609591:web:badf583e8565793a5103f2"
};

const firebaseDynamicLinks = new FirebaseDynamicLinks(firebaseConfig.apiKey);

const firebaseApp = initializeApp(firebaseConfig);

export const firebaseAuth = getAuth(firebaseApp);
export const firebaseFirestore = getFirestore(firebaseApp);

export const currentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, (userFirebase) => {
      unsubscribe()
      resolve(userFirebase)
    }, reject);
  });
}
class FirebasePlugin {

  static install(Vue: typeof _Vue) {
    Vue.prototype.$firebase = new FirebasePlugin();
  }

  get auth() { return new FirebaseAuthPlugin(); }

  get firestore() { return firebaseFirestore; }


  async uploadFile(projectId: string, fileType: string, file: Blob): Promise<string> {

      const storage = getStorage();
      const storageRef = ref(storage, `${projectId}/${fileType}.pdf`);
      
      
      await uploadBytes(storageRef, file); 
      const shareableUrl = await getDownloadURL(storageRef);
      return shareableUrl;
      
  }

  async createLink(link: string, title: string, descr: string): Promise<string> {

    const shortLink = await firebaseDynamicLinks.createLink({
      dynamicLinkInfo: {
        domainUriPrefix: 'https://plan.alinocam.com/link',
        link: link,
        socialMetaTagInfo: {
          socialTitle: title,
          socialDescription: descr
        },
      },

    });

    console.log(shortLink)
    return shortLink.shortLink;

  }

}

class FirebaseAuthPlugin {

  async forgotMail(email: string): Promise<void> {
    await sendPasswordResetEmail(firebaseAuth, email)
  }

  async login(email: string, password: string): Promise<void> {
    await signInWithEmailAndPassword(firebaseAuth, email, password);
  }

  async logout() {
    await signOut(firebaseAuth);
  }

  get uid(): string | undefined {
    return firebaseAuth.currentUser?.uid;
  } 

  get mail(): string {
    if (firebaseAuth.currentUser == null || firebaseAuth.currentUser.email == null) return ''
    else return firebaseAuth.currentUser.email;
  } 


  
}

_Vue.use(FirebasePlugin);
