























































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import { Address, Project } from "@/models/project/project";

@Component({
  components: {
    TextButton,
    IconButton,
  },
})
export default class MapSelection extends Vue {
  zoomLevel = 4;
  savingStatus = 0;
  geolocation = { lat: 48, lng: 11 };
  loadingMapData = false;
  errorLoading = false;
  tiltMap = 0;
  updateMap = false;
  coords = {
    lat: 48,
    lng: 11,
    zoom: this.zoomLevel,
  };
  @Prop() projectCoords!: any;
  @Prop() project!: Project;
  @Prop() addr!: Address;

  @Watch("projectCoords")
  onMapSelected(): void {
    this.removeSelection();

    this.coords = { ...this.projectCoords, zoom: 17 };
    this.zoomLevel = 17;
    this.$nextTick(() => {
      console.log(this.coords);
      this.updateMap = !this.updateMap;
    });
  }

  removeSelection(): void {
    this.savingStatus = 0;
    this.$emit("update");
  }

  centerMap(): void {
    this.zoomLevel = 17;
    this.tiltMap = 0;
    this.updateMap = !this.updateMap;
  }

  async saveMap(): Promise<void> {
    this.savingStatus = 1;
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/staticmap?center=${this.coords.lat},${this.coords.lng}&zoom=${this.coords.zoom}&size=380x380&maptype=satellite&scale=2&key=AIzaSyD-GfbKPxVjIVLs3c3vw_N1xor-eWdcrT8`
    );

    const blob = await response.blob();

    this.savingStatus = 2;
    const base64Image = await new Promise((onSuccess, onError) => {
      try {
        const reader = new FileReader();
        reader.onload = function () {
          onSuccess(this.result);
        };
        reader.readAsDataURL(blob);
      } catch (e) {
        onError(e);

        this.savingStatus = 4;
      }
    });

    if (this.savingStatus != 4) {
      this.savingStatus = 3;
    }

    const fileHandle = await this.$pdf.createMapsProjectPlan(
      base64Image as string
    );
    const metersPerPx =
      (156543.03392 * Math.cos((this.coords.lat * Math.PI) / 180)) /
      Math.pow(2, this.coords.zoom);
    console.log(metersPerPx);
    this.$emit("update", {
      file: fileHandle,
      pixelPerMeter: (4 / metersPerPx) * 1.5,
    });
  }

  zoomMapEvent(zoomLevel: number): void {
    if (this.coords == null) return;
    this.coords.zoom = zoomLevel;
    // console.log("ZOOM CHANGED", zoomLevel)
  }

  centerMapEvent(data: any): void {
    if (this.coords == null) return;

    this.coords.lat = data.lat();
    this.coords.lng = data.lng();
    // console.log("ZOOM CHANGED", data.lat(), data.lng())
  }
}
