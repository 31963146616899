































































import IconButton from "@components/button/IconButton.vue";
import ProjectDialog from "@/components/dialogs/ProjectDialog.vue";

import Loading from "@/components/Loading.vue";
import ProjectBoard from "@/components/ProjectBoard.vue";
import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import { ProjectController } from "@/controllers/project_controller";
import { Label, StatusLog } from "@/models/project/project";
import { CanvasTool, ToolConfig, ToolGroup } from "@/models/tools";
import { SelectTool } from "@/models/tools/select_tool";
import { LabelTool } from "@/models/tools/label_tool";
import { MoveTool } from "@/models/tools/move_tool";
import { RemoveTool } from "@/models/tools/remove_tool";
import { BorderTool } from "@/models/tools/border_tool";
import { DrawCameraTool } from "@/models/tools/draw_camera_tool";
import { DrawPolygonTool } from "@/models/tools/draw_polygon_tool";
import { DrawTreeTool } from "@/models/tools/draw_tree_tool";
import { DrawContainerTool } from "@/models/tools/draw_container_tool";
import MeasureDialog from "@/components/dialogs/MeasureDialog.vue";
import InfoPanel from "@/components/info/InfoPanel.vue";
import Canvas from "@/components/Canvas.vue";
import UploadProjectPlan from "@/components/dialogs/UploadProjectPlan.vue";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import ToolButton from "@/components/ToolButton.vue";
import AppBar from "@/components/AppBar.vue";
import { Shape } from "@/models/project/shapes";
import { Camera } from "@/models/project/camera";

@Component({
  components: {
    UploadProjectPlan,
    InfoPanel,
    IconButton,
    ProjectDialog,
    Loading,
    MeasureDialog,
    ProjectBoard,
    AppBar,
    ToolButton,
  },
})
export default class ProjectPlan extends Vue {
  deviceConfig = require("../../config.json");
  endStatus = 0;
  loadingCanvasImage = true;
  errorLoadingCanvasImg = false;
  dialog = false;
  expandStatusMenu = false;
  controller: ProjectController | null = null;

  selectedTool: CanvasTool | null = null;

  get isReviewer(): boolean {
    return this.controller?.project.info.reviewer == this.$firebase.auth.mail;
  }

  get status(): StatusLog | undefined {
    if (this.statusLog.length == 0) return undefined;
    return this.statusLog[0];
  }

  get statusLog(): Array<StatusLog> {
    if (this.controller == null || this.controller.project == null) return [];
    return [...(this.controller?.project.statusLog || [])].reverse();
  }

  get showInfoPanel(): boolean {
    return (this.controller?.project?.statusLog ?? []).length > 0;
  }

  get isReadonly(): boolean {
    console.log("IS READONLY", this.status, this.isReviewer);

    if (this.status == null) return true;

    if (this.isReviewer != true) {
      switch (this.status.status) {
        case 1:
          return true;
        case 2:
          return (
            this.controller != null &&
            (this.controller.project.shapes == null ||
              this.controller.project.shapes.length == 0)
          );
        case 3:
          return true;
        case 4:
          return !this.isReviewer;
        case 5:
          return false;
        case 6:
          return true;
        default:
          return true;
      }
    } else {
      switch (this.status.status) {
        case 1:
          return true;
        case 2:
          return true;
        case 3:
          return true;
        case 4:
          return false;
        case 5:
          return false;
        case 6:
          return true;
        default:
          return true;
      }
    }
  }

  get selectedObject(): Camera | Shape | Label | null {
    if (this.selectedTool instanceof SelectTool) {
      var selectedObj = this.selectedTool.focused;
      if (selectedObj == null) return null;
      if (selectedObj.startsWith("c")) {
        if (this.controller == null || this.controller.cameras == null)
          return null;
        var cam =
          this.controller.cameras[parseInt(selectedObj.replace("c", ""))];
        return cam?.camera ?? null;
      } else if (selectedObj.startsWith("p")) {
        if (selectedObj == "p0") {
          this.selectToolByName("move");
          return null;
        }
        if (this.controller == null || this.controller.shapes == null)
          return null;
        var poly =
          this.controller.shapes[parseInt(selectedObj.replace("p", ""))];
        return poly?.shape ?? null;
      } else if (selectedObj.startsWith("l")) {
        if (this.controller == null || this.controller.labels == null)
          return null;
        var label =
          this.controller.labels[parseInt(selectedObj.replace("l", ""))];
        return label?.label ?? null;
      }
    }
    return null;
  }

  fitPlanSnapshot(): void {
    if (this.selectedTool?.name != "move") {
      this.selectToolByName("move");
    }

    this.$nextTick(() => {
      ((this.$refs.board as ProjectBoard).$refs.canvas as Canvas).fitMode =
        "window";
      this.fitPlanToView();
      // this.$toast.info('Projektplan wurde automatisch skaliert.')
      // this.board.fitPlanToView();
    });
  }

  @Watch("isReadonly", { immediate: true })
  onReadonlyChange(): void {
    if (this.isReadonly == true && this.selectedTool?.name != "move") {
      this.selectToolByName("move");
    }
  }

  get board(): ProjectBoard {
    return this.$refs.board as ProjectBoard;
  }

  async loadedCanvasImage(status: boolean): Promise<void> {
    if (status == false) {
      const storage = getStorage();

      if (
        !this.controller ||
        !this.controller.project ||
        !this.controller.project.files
      )
        return;
      const shareableUrl = await getDownloadURL(
        ref(storage, `${this.controller.project.id}/image.webp`)
      );
      const shareableThumbnailUrl = await getDownloadURL(
        ref(storage, `${this.controller.project.id}/thumbnail.webp`)
      );

      if (shareableUrl != null || shareableThumbnailUrl == null) {
        this.controller.project.files.image = shareableUrl;
        this.controller.project.files.thumbnail = shareableUrl;
      } else {
        this.errorLoadingCanvasImg = true;
        this.$toast.error("Projektplan konnte nicht geladen werden.");
      }
    } else {
      this.errorLoadingCanvasImg = false;
    }
    this.loadingCanvasImage = false;
  }

  async mounted(): Promise<void> {
    this.$project.watchProjectById(
      this.$route.params.projectId,
      async (project) => {
        if (project == null) {
          return this.$router.push({ name: "overview" });
        } else {
          if (project.members[this.$firebase.auth.uid as string] == null) {
            if (this.$route.query != null && this.$route.query.remove != null)
              return;

            var updatedProject = project;
            if (project.statusLog[project.statusLog.length - 1].status == 4) {
              //
              let reviewerId = null;

              Object.keys(updatedProject.members).forEach(
                (memberId: string) => {
                  if (updatedProject.members[memberId].role == "reviewer")
                    reviewerId = memberId;
                }
              );

              if (reviewerId != null) {
                updatedProject.members[reviewerId as string] = {
                  role: "editor",
                };
              }

              updatedProject.members[this.$firebase.auth.uid as string] = {
                role: "reviewer",
              };

              updatedProject.info.reviewer = this.$firebase.auth.mail;
            } else {
              updatedProject.members[this.$firebase.auth.uid as string] = {
                role: "editor",
              };
            }

            console.log("C");
            return await this.$project.updateProject(updatedProject);
          }

          this.controller = new ProjectController(project);

          if (this.selectedTool == null) {
            this.selectToolByName("move");
          }
          if (this.controller != null) {
            this.selectedTool!.controller = this.controller;
          }
        }
      }
    );

    window.onkeydown = this.handleKeyDown;
    window.onkeyup = this.handleKeyUp;
  }

  readonly tools = [
    new ToolConfig({ name: "move", icon: "", shortcut: "m", tool: MoveTool }),
    new ToolConfig({
      name: "pointer",
      icon: "mdi-cursor-default",
      tooltip: "Komponenten auswählen [S]",
      shortcut: "s",
      tool: SelectTool,
    }),
    new ToolConfig({
      name: "erase",
      icon: "mdi-eraser",
      tooltip: "Komponenten löschen [E]",
      shortcut: "e",
      tool: RemoveTool,
    }),
    new ToolGroup({
      name: "shape",
      tools: [
        new ToolConfig({
          name: "polygon",
          icon: "mdi-shape-polygon-plus",
          tooltip: "Polygon [P]",
          shortcut: "p",
          tool: DrawPolygonTool,
        }),
        new ToolConfig({
          name: "tree",
          icon: "mdi-tree",
          tooltip: "Baum [B]",
          shortcut: "b",
          tool: DrawTreeTool,
        }),
        new ToolConfig({
          name: "container",
          icon: "mdi-train-car-container",
          tooltip: "Container [C]",
          shortcut: "c",
          tool: DrawContainerTool,
        }),
      ],
    }),
    new ToolConfig({
      name: "label",
      icon: "mdi-format-text",
      tooltip: "Textfeld [T]",
      shortcut: "t",
      tool: LabelTool,
    }),
    new ToolConfig({
      name: "border",
      icon: "mdi-border-outside",
      tooltip: "Projektgrenzen bearbeiten",
      tool: BorderTool,
    }),
    new ToolGroup({
      name: "cameras",
      tools: [
        new ToolConfig({
          name: "arming-cameras",
          icon: "mdi-cctv",
          tooltip: "Melder auswählen",
          tool: DrawCameraTool.forType("armingCamera"),
        }),
        new ToolConfig({
          name: "input-device",
          icon: "mdi-account-wrench",
          tooltip: "Bedienteil auswählen",
          tool: DrawCameraTool.forType("inputDevice"),
        }),
        new ToolConfig({
          name: "alarm",
          icon: "mdi-alarm-bell",
          tooltip: "Alarmanlage (Sirene) auswählen",
          tool: DrawCameraTool.forType("alarm"),
        }),
        new ToolConfig({
          name: "gateway",
          icon: "mdi-router-wireless",
          tooltip: "Gateway auswählen",
          tool: DrawCameraTool.forType("gateway"),
        }),
        new ToolConfig({
          name: "video-tower",
          icon: "mdi-transmission-tower",
          tooltip: "Video-Turm auswählen",
          tool: DrawCameraTool.forId("videoTower"),
        }),
        new ToolConfig({
          name: "alinocam",
          icon: "mdi-camera-wireless",
          tooltip: "AlinoCam-Box auswählen",
          tool: DrawCameraTool.forId("alinoCam"),
        }),
      ],
    }),
  ];

  selectToolWhere(
    predicate: (t: ToolConfig) => boolean,
    options?: any
  ): boolean {
    for (var tool of this.tools) {
      if (tool instanceof ToolConfig) {
        if (predicate(tool)) {
          this.selectTool(tool, options);
          return true;
        }
      } else {
        for (var stool of tool.tools) {
          if (predicate(stool)) {
            tool.focusedIndex = tool.tools.indexOf(stool);
            this.selectTool(stool, options);
            return true;
          }
        }
      }
    }
    return false;
  }

  selectToolByName(name: string, options?: any): void {
    this.selectToolWhere((tool) => tool.name == name, options);
  }

  selectTool(tool: ToolConfig, options?: any): void {
    if (this.controller == null) return;

    if (this.selectedTool?.name == tool.name) {
      if (this.selectedTool?.name != "move") {
        this.selectToolByName("move");
      }
    } else {
      const t = (this.selectedTool = new tool.tool(options));
      t.name = tool.name;
      t.controller = this.controller;
      t.selectNext = this.selectToolByName.bind(this);

      if (tool.name != "pointer") {
        this.controller.clearEmptyLabels();
      }
    }
  }

  handleKeyDown(event: KeyboardEvent): void {
    if (event.target == document.body) {
      if (event.keyCode == 32) {
        if (this.selectedTool?.name == "move") this.selectToolByName("pointer");
        else this.selectToolByName("move");
      } else {
        var key = event.key.toLowerCase();
        var didSelect = this.selectToolWhere((tool) => tool.shortcut == key);
        if (!didSelect) {
          this.selectedTool?.onKeyDown?.call(this.selectedTool, event);
        }
      }
    }
  }

  handleKeyUp(event: KeyboardEvent): void {
    if (event.target == document.body) {
      this.selectedTool?.onKeyUp?.call(this.selectedTool, event);
    }
  }

  openPlanFileSettings(): void {
    (this.$refs.measureDialog as MeasureDialog).open();
  }

  async openProjectSettings(): Promise<void> {
    (this.$refs.projectDialog as ProjectDialog).open();
  }

  fitPlanToView(): void {
    (this.$refs.board as ProjectBoard).fitPlanToView();
  }

  scheduledUpdate?: number;
  isSync = false;

  @Watch("controller.project", { deep: true })
  scheduleUpdate(): void {
    if (
      this.status &&
      this.status.status == 1 &&
      this.controller != null &&
      this.controller.project != null &&
      this.controller.project.files != null &&
      this.controller.project.files.image != null
    ) {
      if (
        this.controller.project.measure != null &&
        this.controller.project.measure.pixelPerMeter != null
      ) {
        this.$project.updateProjectStatus(this.$route.params.projectId, 2, "");
      } else {
        this.$nextTick(() => {
          (this.$refs.measureDialog as MeasureDialog).open();
        });
      }
    } else if (
      this.controller != null &&
      this.controller.project != null &&
      this.status &&
      this.status.status == 2 &&
      this.controller.project.shapes.length == 0
    ) {
      if (this.selectedTool?.name != "polygon")
        this.selectToolByName("polygon");
    }

    clearTimeout(this.scheduledUpdate);
    this.scheduledUpdate = window.setTimeout(() => {
      if (this.controller != null) {
        this.isSync = true;
        this.$project.updateProject(this.controller.project).finally(() => {
          this.isSync = false;
        });
      }
    }, 500);
    // console.log("HA")
    // if (this.isSync == true) return;

    // if (this.status && this.status.status == 1 && this.controller != null && this.controller.project != null && this.controller.project.files != null && this.controller.project.files.image != null) {

    //   if (this.controller.project.measure != null && this.controller.project.measure.pixelPerMeter != null) {
    //     this.$project.updateProjectStatus(this.$route.params.projectId, 2, '');
    //   } else {
    //     this.$nextTick(() => {
    //               (this.$refs.measureDialog as MeasureDialog).open()

    //     });
    //   }
    // } else if (this.controller != null && this.controller.project != null && this.status && this.status.status == 2 && this.controller.project.shapes.length == 0) {
    //   if (this.selectedToolName != 'shape') this.selectTool('shape')
    // }
    //   if (this.controller != null && prev != null) {
    //     this.isSync = true;
    //     this.$project.updateProject(this.controller.project).finally(() => {
    //       // this.isSync = false
    //       setTimeout(() => {
    //         this.isSync = false;
    //       }, 500);

    //     });
    //   }
  }
}
