import _Vue from 'vue';
import Vue from 'vue';
import * as emailjs from 'emailjs-com';
import { Project } from '@/models/project/project';

declare module 'vue/types/vue' {
  export interface Vue {
      $mail: MailPlugin
  }
}

class MailPlugin {


  static install(Vue: typeof _Vue) {
    Vue.prototype.$mail = new MailPlugin();
  }

  async sendStatusChangeMail(owner: string, subject: string, comment: string, project: Project, link: string): Promise<void> {
      
      await emailjs.send('service_x6o78aq', 'alinoplan_review_info', {
        from: owner,
        subject: subject,
        comment: comment,
        url: project.files!.file,
        link: link,
        id: (project.info.offerId || '-'),
        name: project.name,
        projectId: project.id,
        createdAt: project.createdAt, 
        updatedAt: project.updatedAt

    }, 'LLHLE_SjjU9TdmtJZ')
  }

  async finishPlanningMail(owner: string, comment: string, project: Project, link: string, planLink: string): Promise<void> {
   console.log('HRERERERR', Vue.prototype.$firebase.auth.mail)
   
    await emailjs.send('service_x6o78aq', 'alinoplan_finish_plan', {
      owner: owner,
      comment: comment,
      url: project.files!.file,
      offerLink: project.files!.offer,
      planLink: planLink,
      descr: project.info.descr,
      addr: `${project.info.address.street}, ${project.info.address.postal} ${project.info.address.city}`,
      contactName: project.info.contact.name || '-',
      contactMail: project.info.contact.email || '-',
      link: link,
      id: (project.info.offerId || '-'),
      name: project.name,
      projectId: project.id,
      projectAims: project.info.projectAim || '-',
      projectHints: project.info.projectHints || '-'
  }, 'LLHLE_SjjU9TdmtJZ')
}

}




_Vue.use(MailPlugin);
