































import IconButton from "@components/button/IconButton.vue";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { ToolConfig, ToolGroup } from "@/models/tools";

@Component({
  name: 'ToolButton',
  components: { IconButton, ToolButton },
})
export default class ToolButton extends Vue {

  @Prop()
  readonly tool!: ToolConfig | ToolGroup;

  @Prop()
  readonly selectedTool!: ToolConfig;

  @Prop()
  readonly isInGroup?: boolean;

  get isTool(): boolean {
    return this.tool instanceof ToolConfig;
  }

  selectTool(tool: ToolConfig): void {
    if (this.tool instanceof ToolGroup) {
      this.tool.focusedIndex = this.tool.tools.indexOf(tool);
    }
    this.$emit("select", tool);
  }
}
