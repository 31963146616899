





















import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

const colors = [
  "#e6194B",
  "#f58231",
  "#ffe119",
  "#bfef45",
  "#3cb44b",
  "#42d4f4",
  "#4363d8",
  "#911eb4",
  "#f032e6",
  "#ffffff",
  "#a9a9a9",
  "#000000",
];

@Component({})
export default class ColorSelector extends Vue {
  colors = colors;

  @Prop() value!: string;
  @Prop() canRemove?: boolean;
}
