import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Login from '@/views/LoginView.vue'
import Overview from '@/views/OverviewView.vue'
import Project from '@/views/ProjectPlanView.vue'
import {currentUser} from '../plugins/firebase';

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  { 
    path: '',
    redirect: 'overview'
  },
  { 
    path: '*',
    redirect: 'overview'
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresNoAuth: true
    }
  },
  {
    path: '/overview',
    name: 'overview',
    component: Overview,
    meta: {
      requiresAuth: true
    }
  },

  
  {
    path: '/project/:projectId',
    name: 'project',
    component: Project,
    meta: {
      requiresAuth: true
    }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  const requiresNoAuth = to.matched.some(record => record.meta.requiresNoAuth);
  if (!requiresAuth && !requiresNoAuth) {
    next();
  } else {
    const user = await currentUser();
    if (requiresAuth && !user) {
      next({name: 'login', query: {route: to.fullPath}});
    } else if (requiresNoAuth && user) {
      next('');
    } else {
      next();
    }
  }
});

export default router
