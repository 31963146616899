



































































import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import TextInput from "@components/text/TextInput.vue";

import DialogTemplate from "@components/dialog/DialogTemplate.vue";
import { Project, StatusLog } from "@/models/project/project";
import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";
import FurtherInfoAlert from "./FurtherInfoAlert.vue";

@Component({
  components: {
    DialogTemplate,
    TextButton,
    IconButton,
    TextInput,
    FurtherInfoAlert,
  },
})
export default class StatusChangeDialog extends Vue {
  @Prop() currStatus!: number;
  @Prop() endStatus!: number;
  @Prop() statusLogs!: StatusLog[];

  infoMail = "";
  isFixedMail = false;

  @Watch("endStatus", { immediate: true })
  onEndStatusChange(): void {
    if (this.endStatus == 5) {
      this.infoMail = this.statusLogs[1].mail;
      this.isFixedMail = true;
    }
  }
  statusHandle = {
    1: {
      name: "Projekterstellung",
      title: "Projekt neu erstellen",
      descr: "",
      button: "Speichern",
      link: "https://google.com",
    },
    2: {
      name: "Projektentwurf",
      title: "Angebot überarbeiten",
      descr: "Angebot anpassen oder weitere Melder/Kameras hinzufügen.",
      button: "Status wechseln",
      link: "https://quick-bowl-4e0.notion.site/Techn-berpr-fung-anfordern-8741d8307dd14902844af55adaa0acdd",
    },
    3: {
      name: "Projektierung (f. Angebote)",
      title: "Projektierung",
      descr: "",
      button: "Status wechseln",
      link: "https://google.com",
    },
    4: {
      name: "Techn. Überprüfung (Review)",
      title: "Reviewprozess",
      descr:
        "Bevor das Angebot zur Umsetzung an die Technik weitergegeben werden darf, muss eine technische Machbarkeitsprüfung durchgeführt werden. Ein Mitarbeiter des technischen Innendienstes prüft die Projektierung, fordert ggf. Überarbeitungen an und komplettiert schließlich die Projektierung.",
      button: "Review anfordern",
      link: "https://quick-bowl-4e0.notion.site/Techn-berpr-fung-anfordern-8741d8307dd14902844af55adaa0acdd",
    },
    5: {
      name: "Überarbeitung",
      title: "Überarbeitung anfordern",
      descr:
        "Als Prüfer können Sie vom Ersteller eine Überarbeitung des Projekts verlangen. Bitte begründen Sie ausführlich, welche Änderungen vorgenommen werden sollen.",
      button: "Anfordern",
      link: "https://quick-bowl-4e0.notion.site/berarbeitung-anfordern-2e6180e05bab4f9bb9a50582b5739863",
    },
    6: {
      name: "Projektfreigabe",
      title: "Überarbeitung anfordern",
      descr:
        "Als Prüfer können Sie vom Ersteller eine Überarbeitung des Projekts verlangen. Bitte begründen Sie ausführlich, welche Änderungen vorgenommen werden sollen.",
      button: "Anfordern",
      link: "https://quick-bowl-4e0.notion.site/berarbeitung-anfordern-2e6180e05bab4f9bb9a50582b5739863",
    },

    7: {
      name: "Abgeschl. Projektierung",
      title: "Überarbeitung anfordern",
      descr:
        "Als Prüfer können Sie vom Ersteller eine Überarbeitung des Projekts verlangen. Bitte begründen Sie ausführlich, welche Änderungen vorgenommen werden sollen.",
      button: "Anfordern",
      link: "https://quick-bowl-4e0.notion.site/berarbeitung-anfordern-2e6180e05bab4f9bb9a50582b5739863",
    },
  };
  fileSource: Blob | null = null;
  changeComment = "";
  loadingUpload = false;
  errorOccured = false;
  selectedFile: Blob | null = null;

  @Watch("dialog")
  onDialogChange(): void {
    if (this.dialog == true) return;
    setTimeout(() => {
      this.loadingUpload = false;
      this.changeComment = "";
      this.isFixedMail = false;
      this.infoMail = "";
    }, 250);
  }

  @Watch("currStatus")
  onStatusChange(): void {
    this.dialog = false;
    this.loadingUpload = false;
    this.changeComment = "";
    this.isFixedMail = false;
    this.infoMail = "";
  }
  dialog = false;
  public project: Partial<Project> | null = null;

  get isValidComment(): boolean {
    if (this.changeComment.length > 1) {
      var commentCheck = this.changeComment.toLowerCase().replace(/ /g, "");

      if (
        commentCheck == "-" ||
        commentCheck == "k.a." ||
        commentCheck == "ka" ||
        commentCheck == "k.a" ||
        commentCheck == "keineangabe" ||
        "------------------------".includes(commentCheck) ||
        "_________________".includes(commentCheck) ||
        ".................".includes(commentCheck) ||
        "keineangabe".includes(commentCheck) ||
        "unbekannt".includes(commentCheck) ||
        "keineinformationen".includes(commentCheck) ||
        "nichtbekannt".includes(commentCheck) ||
        "keineangaben".includes(commentCheck) ||
        "keineahnung".includes(commentCheck) ||
        commentCheck == "ohneangabe" ||
        commentCheck == "keineahnung" ||
        commentCheck == "unbekannt"
      )
        return false;

      return true;
    }

    return false;
  }

  upload(file: Blob): void {
    this.selectedFile = file;
  }

  async save(): Promise<void> {
    if (this.project == null) return;
    this.loadingUpload = true;
    console.log(this.endStatus, this.changeComment);

    this.infoMail = "steffen.linssen@alinocam.com";

    if (
      this.endStatus == 5 &&
      (this.isFixedMail == true || this.infoMail.length > 0)
    ) {
      this.$mail.sendStatusChangeMail(
        this.$firebase.auth.mail as string,
        "Überarbeitung der Projektierung notwendig",
        this.changeComment,
        this.project as Project,
        document.location.toString()
      );
    }

    if (this.endStatus == 4) {
      this.$mail.sendStatusChangeMail(
        this.$firebase.auth.mail as string,
        "techn. Überprüfung angefordert",
        this.changeComment,
        this.project as Project,
        `${document.location.toString()}`
      );
    }

    if (this.endStatus == 6) {
      this.$mail.sendStatusChangeMail(
        this.$firebase.auth.mail as string,
        "Überprüfung erfolgreich abgeschlossen - Freigabe erforderlich",
        this.changeComment,
        this.project as Project,
        `${document.location.toString()}`
      );
    }

    console.log(this.endStatus);
    // await this.$project.updateProject(projectHandle as Project)

    this.$project.updateProjectStatus(
      this.$route.params.projectId,
      this.endStatus,
      this.changeComment
    );
  }

  close(): void {
    this.dialog = false;
    this.loadingUpload = false;
  }

  open(project?: Project): void {
    this.project = { ...(project ?? {}) };

    this.errorOccured = false;
    this.dialog = true;
  }
}
