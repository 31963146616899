













import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import { Polygon } from "@/models/project/shapes";
import DetailsPanel from "../DetailsPanel.vue";
import ColorSelector from "../ColorSelector.vue";

@Component({ components: { DetailsPanel, ColorSelector } })
export default class PolygonOptions extends Vue {
  @Prop() polygon!: Polygon;

  get options(): any {
    return this.polygon.options != null ? this.polygon.options : {};
  }

  setOptions(options: any): void {
    Vue.set(this.polygon, "options", { ...this.options, ...options });
  }
}
