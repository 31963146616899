import { Point } from "./project";

export enum ShapeType {
  polygon = 'polygon', tree = 'tree', container = 'container'
}
export type Shape = Polygon | Tree | Container;

export interface Polygon {
  type: ShapeType.polygon;
  points: Point[];
  bounded: boolean;

  options?: {
    color?: string;
  }
}

export interface Tree {
  type: ShapeType.tree;
  origin: Point;
  radius: number;
}

export interface Container {
  type: ShapeType.container;
  origin: Point;
  rotation: number;
  size: ContainerSize;
}

export enum ContainerSize {
  std20 = 'std20', std40 = 'std40', high40 = 'high40', high45 = 'high45'
}

export type ContainerDimension = {width: number, length: number, height: number};

export const containerDimensions: { [ key in ContainerSize]: ContainerDimension} = ({
  std20: {width: 2.4, length: 6, height: 2.6},
  std40: {width: 2.4, length: 12.2, height: 2.6},
  high40: {width: 2.4, length: 12.2, height: 2.9},
  high45: {width: 2.4, length: 13.7, height: 2.9},
});