













import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import { Camera } from "@/models/project/camera";
import { Label } from "@/models/project/project";
import { Shape } from "@/models/project/shapes";
import { CanvasTool } from "@/models/tools";
import { TypesCameraTool } from "@/models/tools/draw_camera_tool";
import VideoTowerOptions from "./options/VideoTowerOptions.vue";
import AlinoCamOptions from "./options/AlinoCamOptions.vue"
import DeviceOptions from "./options/DeviceOptions.vue";
import CameraOptions from "./options/CameraOptions.vue";
import PolygonOptions from "./options/PolygonOptions.vue";
import TreeOptions from "./options/TreeOptions.vue";
import LabelOptions from "./options/LabelOptions.vue";
import ContainerOptions from "./options/ContainerOptions.vue";

@Component({
  components: {
    VideoTowerOptions,
    AlinoCamOptions,
    DeviceOptions,
    CameraOptions,
    PolygonOptions,
    TreeOptions,
    LabelOptions,
    ContainerOptions
  },
})
export default class DetailsPanel extends Vue {
  @Prop() object!: Camera | Shape | Label | null;
  @Prop() tool!: CanvasTool | null;

  get isVideoTowerTool(): boolean {
    return this.tool?.name == "video-tower";
  }

  get isAlinoCamTool(): boolean {
    return this.tool?.name == "alinocam";
  }

  get isCameraTypesTool(): boolean {
    return this.tool instanceof TypesCameraTool;
  }

  get isCamera(): boolean {
    return this.has("arcs");
  }

  get isPolygon(): boolean {
    return this.has("points") || (this.tool?.name == "polygon" && (this.tool as any).hasUnbounded);
  }

  get isTree(): boolean {
    return this.has("radius") || this.tool?.name == "tree";
  }

  get isContainer(): boolean {
    return this.has("size") || this.tool?.name == "container";
  }

  get isLabel(): boolean {
    return this.has("text") || this.tool?.name == "label";
  }

  get(prop: string): any {
    return this.object ?? (this.tool as any)[prop];
  }

  has(prop: string): boolean {
    return this.object != null && (this.object as any)[prop] != null;
  }

  onUpdate(): void {
    if (this.tool?.name == "pointer") {
      this.tool.controller.updateAll();
    }
  }
}
