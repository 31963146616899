import { CameraController } from "@/controllers/camera_controller";
import { ContainerController } from "@/controllers/container_controller";
import { LabelController } from "@/controllers/label_controller";
import { PolygonController } from "@/controllers/polygon_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { SceneContext } from "konva/lib/Context";
import { Shape } from "../project/shapes";
import { CanvasLine, CanvasPath, CanvasPoint, CanvasShape, CanvasText } from "../shapes";
import { CanvasContext, CanvasTool, ShapeMouseEvent } from "../tools";
import { MoveTool } from "./move_tool";

export class RemoveTool extends CanvasTool {

  constructor() {
    super();
    this.onShapeClick = RemoveTool.removeShape;
  }

  onStageScale = MoveTool.scaleCanvas;

  isShiftPressed = false;
  isMouseDown = false;

  cursor = 'default';
  getCursor = (): string => this.isShiftPressed ? this.isMouseDown ? 'grabbing' : 'grab' : this.cursor;

  onKeyDown = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = true;
      this.onStageDrag = MoveTool.dragCanvas;
      this.onShapeClick = null;
    }
  }

  onKeyUp = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = false;
      this.onStageDrag = null;
      this.onShapeClick = RemoveTool.removeShape;
    }
  }

  onStageMouseDown = (): void => {
    this.isMouseDown = true;
  }

  onStageMouseUp = (): void => {
    this.isMouseDown = false;
  }

  onShapeMouseEnter = (e: ShapeMouseEvent): void => {
    if (e.shape instanceof CanvasLine && e.shape.bounded) {
      this.cursor = 'pointer';
    } else if (e.shape instanceof CanvasPath) {
      this.cursor = 'pointer';
    } else if (e.shape instanceof CanvasPoint && e.shape.controller instanceof CameraController) {
      this.cursor = 'pointer';
    } else if (e.shape instanceof CanvasPoint && e.shape.controller instanceof TreeController) {
      this.cursor = 'pointer';
    } else if (e.shape instanceof CanvasText) {
      this.cursor = 'pointer';
    }
  }

  onShapeMouseLeave = (): void => {
    this.cursor = 'default';
  }

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index,
      interactive: true,
      focused: true,
      draggable: false,
      bounds: this.controller.bounds,
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`, 
        stroked: true, 
        focused: false, 
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: true,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: true,
        stroked: true,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: true, context: context?.context });
  }

  static removeShape(this: CanvasTool, e: ShapeMouseEvent): void {
    const shape = e.shape;
    if (shape instanceof CanvasLine) {
      const controller = shape.controller;
      if (controller instanceof PolygonController) {
        if (controller.shape.bounded) {
          this.controller?.removeShape(controller);
          this.onShapeMouseLeave?.call(this, e);
        }
      } else if (controller instanceof ContainerController) {
        this.controller?.removeShape(controller);
        this.onShapeMouseLeave?.call(this, e);
      }
    } else if (shape instanceof CanvasPoint) {
      const controller = shape.controller;
      if (controller instanceof CameraController) {
        this.controller?.removeCamera(controller);
        this.onShapeMouseLeave?.call(this, e);
      } else if (controller instanceof TreeController) {
        this.controller?.removeShape(controller);
        this.onShapeMouseLeave?.call(this, e);
      } else if (controller instanceof ContainerController) {
        this.controller?.removeShape(controller);
        this.onShapeMouseLeave?.call(this, e);
      }
    } else if (shape instanceof CanvasPath) {
      const controller = shape.controller;
      if (controller instanceof CameraController) {
        this.controller?.removeCamera(controller);
        this.onShapeMouseLeave?.call(this, e);
      }
    } else if (shape instanceof CanvasText) {
      this.controller?.removeLabel(shape.controller);
      this.onShapeMouseLeave?.call(this, e);
    }
  }

}
