import Vue from 'vue'
import VueKonva from 'vue-konva';
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './scss/font/stylesheet.css';

import * as VueGoogleMaps from 'vue2-google-maps'
import './plugins/pdf';
import './plugins/mail';

import './plugins/project';
import './plugins/firebase';
import "./scss/toast.scss";

import "vue-toastification/dist/index.css";
import Toast,  { POSITION }   from "vue-toastification";


Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD-GfbKPxVjIVLs3c3vw_N1xor-eWdcrT8',
    libraries: 'places'
  }
})

Vue.use(Toast, {
  transition: "Vue-Toastification__fade",
  maxToasts: 4,
  icon: false,
  position: POSITION.BOTTOM_RIGHT,
  timeout: 2500,
  closeOnClick: true,
  pauseOnFocusLoss: false,
  pauseOnHover: false,
  draggable: false,
  showCloseButtonOnHover: true,
  hideProgressBar: true,
  transitionDuration: {

    enter: 50,
    leave: 50
  }
});


Vue.prototype.$devices = require('../config.json'); 

Vue.use(VueKonva);
Vue.config.productionTip = false



new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
