/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-empty-function */
import { CameraController } from "@/controllers/camera_controller";
import { LabelController } from "@/controllers/label_controller";
import { ProjectController } from "@/controllers/project_controller";
import { ShapeController } from "@/controllers/shape_controller";
import konva from "konva";
import { SceneContext } from "konva/lib/Context";
import { Stage } from "konva/lib/Stage";
import { Point } from "./project/project";
import { Shape } from "./project/shapes";
import { CanvasShape } from "./shapes";

export interface CanvasContext {
  background: CanvasBackground;
  minZoom: number;
  maxZoom: number;
  configKonva: { scaleX: number, scaleY: number };
  updateBackground: () => void;
  context: SceneContext;
  stage: Stage;
}

export interface CanvasBackground {
  x: number;
  y: number;
  width: number;
  height: number;
  fillPatternOffset: Point,
}

export interface StageScaleEvent {
  event: WheelEvent;
  stage: konva.Stage;
  canvas: CanvasContext;
}

export interface StageMouseEvent {
  event: MouseEvent;
  stage: konva.Stage;
  canvas: CanvasContext;
  projectedPoint: Point;
}

export interface ShapeMouseEvent {
  event: MouseEvent;
  target: konva.Shape;
  stage: konva.Stage;
  shape: CanvasShape<any>;
  canvas: CanvasContext;
}

export abstract class CanvasTool {

  name!: string;
  controller!: ProjectController;
  selectNext!: (name: string, options?: any) => void;

  onStageMouseMoved: ((e: StageMouseEvent) => void) | null = null;
  onStageMouseDown: ((e: StageMouseEvent) => void) | null = null;
  onStageMouseUp: ((e: StageMouseEvent) => void) | null = null;
  onStageDrag: ((e: StageMouseEvent) => void) | null = null;
  onStageScale: ((e: StageScaleEvent) => void) | null = null;
  onShapeDrag: ((e: ShapeMouseEvent) => void) | null = null;
  onShapeClick: ((e: ShapeMouseEvent) => void) | null = null;
  onShapeMouseEnter: ((e: ShapeMouseEvent) => void) | null = null;
  onShapeMouseLeave: ((e: ShapeMouseEvent) => void) | null = null;
  onShapeMouseDown: ((e: ShapeMouseEvent) => void) | null = null;
  getCursor: ((shape: CanvasShape<any> | null) => string | null) | null = null;
  onKeyDown: ((e: KeyboardEvent) => void) | null = null;
  onKeyUp: ((e: KeyboardEvent) => void) | null = null;

  abstract renderCamera(controller: CameraController, index: number): CanvasShape<any>[];
  abstract renderShape(controller: ShapeController<Shape>, index: number): CanvasShape<any>[];
  abstract renderLabel(controller: LabelController, index: number, canvas?: CanvasContext): CanvasShape<any>[];
  renderCustomShapes(): CanvasShape<any>[] { return []; }
}

export class ToolConfig {

  name: string;
  icon: string;
  tooltip?: string;
  shortcut?: string;
  tool: new (options?: any) => CanvasTool;

  constructor(options: {name: string, icon: string, tooltip?: string, shortcut?: string, tool: new (options?: any) => CanvasTool}) {
    this.name = options.name;
    this.icon = options.icon;
    this.tooltip = options.tooltip;
    this.shortcut = options.shortcut;
    this.tool = options.tool;
  }

}

export class ToolGroup {

  name: string;
  tools: ToolConfig[];
  focusedIndex = 0;

  constructor(options: {name: string, tools: ToolConfig[]}) {
    this.name = options.name;
    this.tools = options.tools;
  }

}