<template>
  <div class="loading-page">
    <div class="loading-background">
      <img src="@/assets/conplan.webp" />
    </div>
    <div class="center">
      <img
        preload
        width="260"
        height="47"
        class="logo mb-4"
        v-on:click="$router.push({ name: 'login' })"
        src="@/assets/alinoplan_logo.webp"
      />
      <v-progress-circular
        indeterminate
        color="primary"
        width="2"
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.loading-page > * {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.loading-background {
  opacity: 0.4;
  background: black;
}

.loading-background img {
  min-width: 100%;
  min-height: 100%;
}

.center {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}
</style>
