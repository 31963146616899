
import { Point } from "@/models/project/project";
import { Polygon, ShapeType } from "@/models/project/shapes";
import { CanvasLine, CanvasPoint, CanvasShape } from "@/models/shapes";
import { CameraController } from "./camera_controller";
import { LineController } from "./line_controller";
import { distanceTo, projectOnto, normalizePoint, scale, addPoints, dot } from "./point_controller";
import { ShapeController } from "./shape_controller";

class BasePolygonController   {

  shape: Polygon;
  outerAngle: number;

  constructor(polygon: Polygon) {
    this.shape = polygon;
    this.outerAngle = this.calcOuterAngle();
  }

  contains(p: Point): boolean {
    let inside = false;

    if (this.shape.points.length < 3) return false;
  
    for (let i = 0, j = this.shape.points.length - 1; i < this.shape.points.length; j = i++) {
      const pi = this.shape.points[i], pj = this.shape.points[j];
    
      if (((pi.y > p.y) != (pj.y > p.y)) && (p.x < (pj.x - pi.x) * (p.y - pi.y) / (pj.y - pi.y) + pi.x)) { 
        inside = !inside; 
      }
    }
    
    return this.shape.bounded ? inside : !inside;
  }

  moveOutside(point: Point): Point {
    let minDist = Number.MAX_VALUE;
    let outsidePoint ={...point};
    
    for (const line of this.lines) {

      const p = projectOnto(point, line);
      const dist = distanceTo(p, point);
      if (dist < minDist) {
        minDist = dist;
        outsidePoint = p;
      }
    }

    return outsidePoint;
  }

  line(): number[] {
    if (!this.shape.bounded && this.outerAngle == -360) {
      return this.shape.points.flatMap((p) => [p.y, p.x]).reverse();
    } else {
      return this.shape.points.flatMap((p) => [p.x, p.y]);
    }
  }

  get lines(): LineController[] {
    const lines: LineController[] = [];

    if (this.shape.points.length < 2) return lines;

    for (let i = 0, j = this.shape.points.length - 1; i < this.shape.points.length; j = i++) {
      lines.push(new LineController(this.shape.points[i], this.shape.points[j]));
    }

    return lines;
  }

  calcOuterAngle(): number {
    const lines = this.lines;
    let sum = 0;

    for (let i = 0, j = lines.length - 1; i < lines.length; j = i++) {
      const a = normalizePoint(lines[i].vector());
      const b = normalizePoint(lines[j].vector());

      const ang = Math.atan2(a.x*b.y-a.y*b.x, dot(a, b));
      sum += ang;
    }

    return Math.round(sum*180/Math.PI);
  }

}

export class PolygonController extends BasePolygonController implements ShapeController<Polygon> {
  
  offsetPolyA: BasePolygonController;
  offsetPolyB: BasePolygonController;
  offsetPolyA2: BasePolygonController;
  offsetPolyB2: BasePolygonController;

  constructor(polygon: Polygon) {
    super(polygon);

    this.offsetPolyA = this.offsetBy(10, 1);
    this.offsetPolyB = this.offsetBy(10, -1);
    this.offsetPolyA2 = this.offsetBy(11, 1);
    this.offsetPolyB2 = this.offsetBy(11, -1);
  }

  update(): void {
    this.offsetPolyA = this.offsetBy(10, 1);
    this.offsetPolyB = this.offsetBy(10, -1);
    this.offsetPolyA2 = this.offsetBy(11, 1);
    this.offsetPolyB2 = this.offsetBy(11, -1);
    this.outerAngle = this.calcOuterAngle();
  }

  translateBy(offset: Point): void {
    for (const p of this.shape.points) {
      p.x += offset.x;
      p.y += offset.y;
    }
    this.update();
  }

  offsetBy(offset: number, inverse: number): BasePolygonController {
    
    const points: Point[] = [];

    for (let i = 0; i < this.shape.points.length; i++) {
    
      const p = this.shape.points[i];
      
      const a = this.shape.points[(i + this.shape.points.length-1)%this.shape.points.length];
      const b = this.shape.points[(i + 1)%this.shape.points.length];
      
      const la = new LineController(a, p);
      const lb = new LineController(p, b);

      const na = scale(normalizePoint(la.normal()), inverse);
      const nb = scale(normalizePoint(lb.normal()), inverse);

      const bis = normalizePoint(addPoints(na, nb));

      const len = offset / Math.sqrt(1 + dot(na, nb));

      points[i] = addPoints(p, scale(bis, len));
    }

    return new BasePolygonController({type: ShapeType.polygon, points, bounded: this.shape.bounded});
  }

  expell(p: Point): Point {
    if (this.offsetPolyA2.contains(p)) {
      p = this.offsetPolyA2.moveOutside(p);
    }
    if (this.offsetPolyB2.contains(p)) {
      p = this.offsetPolyB2.moveOutside(p);
    }
    return p;
  }

  contains(p: Point): boolean {
    return this.offsetPolyA.contains(p) || this.offsetPolyB.contains(p);
  }

  applyTo(c: CameraController): void {
    for (const line of this.lines) {
      c.apply(line);
    }
  }

  get fillColor(): string {
    const c = this.shape.options?.color;
    if (c != null) {
      return c.substring(0, 7) + 'aa';
    } else {
      return '#aaaa';
    }
  }


  render(options: { id: string, stroked: boolean, focused: boolean, draggable: boolean }): CanvasShape<any>[] {
    const shapes = [] as CanvasShape<any>[];

    if (!this.shape.bounded) {
      shapes.push(new CanvasLine({
        id: options.id,
        controller: this,
        points: this.line(),
        closed: true,
        bounded: false,
        priority: 1,
        style: { 
          fill: '#aaaa',
          ...(options.focused ? {
            stroke: '#cc2027',
            strokeWidth: 3,
            strokeScaleEnabled: false,
          } : {}),
        },
      }));
    } else {
      shapes.push(new CanvasLine({
        id: options.id,
        controller: this,
        points: this.line(),
        closed: true,
        draggable: options.draggable,
        priority: 2,
        style: { 
          fill: this.fillColor,
          ...(options.stroked ? {
            stroke: '#cc2027',
            strokeWidth: options.draggable ? options.focused ? 3 : 0.5 : 2,
            strokeScaleEnabled: false,
          } : {})
        },
      }));
    }


    if (options.focused) {
      for (const i in this.shape.points) {
        const p = this.shape.points[i];
        shapes.push(new CanvasPoint({
          id: `${options.id}-${i}`,
          controller: new PolygonPointController(this, p),
          point: p,
          draggable: options.draggable,
          priority: 6,
          style: {
            fill: 'white',
            stroke: '#00000033',
            strokeWidth: 4,
            strokeScaleEnabled: false,
            fillAfterStrokeEnabled: true,
          },
        }));
      }
    }

    return shapes;
  }
}

export class PolygonPointController {
  polygon: PolygonController;
  point: Point;

  constructor(polygon: PolygonController, point: Point) {
    this.polygon = polygon;
    this.point = point;
  }
}