<template>
  <!-- @contextmenu="handler($event)" -->
  <div class="dialog-template " >
     <v-row  v-show="noHeader != true" align="center" class="ma-0 pt-4 pb-2 pl-4 pr-4">
        <div class="font-weight-bold" style="position: relative; max-width: 90%; font-size: 19px">
        <v-row class="ma-0" align="center">
          <v-icon size="20" class="mr-1" color="black" v-if="icon != null">{{icon}}</v-icon>
          {{ title }}
          
        </v-row>
        </div>
        <slot name="title-button" />

        <v-spacer />
        <slot name="header-action"/>
        <IconButton
          v-if="closeable == true || closeable == undefined"
          icon="mdi-close"
          :dense="true"
          v-on:click="$emit('close')"
        />
      </v-row>

      <div id="dialog-content" :style="noHeader ? 'max-height: calc(90vh - 100px)' : 'max-height: calc(90vh - 100px)'" style=" overflow-y: scroll; overflow-x: hidden!important; position: relative " :class="padding ? 'pl-4 pr-4' : 'pa-0'">
        <slot name="content"/>
      </div>

      <div v-show="actions" style="position: relative; " class="pl-4 pr-4 pt-2 pb-2">
        <slot name="actions"/>
      </div>
  </div>
</template>

<script>
import IconButton from "../button/IconButton.vue";
export default {
  components: {
    IconButton,
  },
  props: {
    actions: {
      type: Boolean, 
      default: true
    },
    title: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
    },
    closeable: {
      type: Boolean,
      default: true
    },
    noHeader: {
      type: Boolean,
      default: false
    },
    icon: {
      default: null
    },
    padding: {
      type: Boolean,
      default: true
    }
  }
};
</script>


<style scoped>
.card-style {
  border-radius: 10px !important;
}

.headline-underline {
  position: absolute;

  bottom: 0px;
  background-color: var(--v-primary-base);
  width: 100%;
  height: 4px;
  border-radius: 2px;
}

.close-button {
  position: absolute;
  right: 15px;
  top: 15px;
}


.dialog-template {
  height: 100%; width: 100%; background-color: white; border-radius: 10px; overflow: hidden!important; overflow: -moz-scrollbars-none;  -ms-overflow-style: none;
}


    .dialog-template ::-webkit-scrollbar { width: 0 !important; display: none }  

  
</style>
