<template>

    <v-text-field :id="id" style="font-family: 'Lato', sans-serif!important;"
        :style="disabled ? 'opacity: .5; pointer-events: none;' : ''" autocomplete="false" class="red--text elevation-0"
        :type="password ? (showPassword ? 'text' : 'password') : (number ? 'number' : 'text')" v-model="inputValue"
        :placeholder="placeholder" height="10" dense flat :pattern="alphanumeric ? '[a-zA-Z0-9\s]+' : ''" hide-details
        filled :solo-inverted="inverted" rounded :readonly="readonly"
        :min="numberBounds != null ? numberBounds.min : null" :max="numberBounds != null ? numberBounds.max : null"
        :prepend-inner-icon="icon" :dark="dark" v-on:focus="$emit('focus', $event)" v-on:blur="$emit('blur', $event)"
        v-on:keydown="$emit('keydown', $event)">

        <template v-slot:prepend-inner="">
            <span v-if="prefix != null && prefix.length > 0" style="font-size: 14px"
                :class="disabled ? 'grey--text' : ''" class="black--text mt-1 mr-2 font-weight-bold">{{ prefix }}:</span>
        </template>

        <template v-slot:append>
            <slot name="append" />
            <div v-if="!disabled">
                <div class="mr-0 ml-1" v-if="suffix != null && suffix.length > 0"
                    style="color: rgba(0, 0, 0, .7); margin-top: 5px; font-size: 14px"><b>{{ suffix }}</b></div>
                <div style="cursor: pointer!important" v-on:click="showPassword = !showPassword" v-if="password">
                    <v-icon v-if="showPassword">mdi-eye</v-icon>
                    <v-icon v-else>mdi-eye-off</v-icon>
                </div>
                <div v-else-if="validate && inputValue && inputValue.length > 0 && !disabled">
                    <!-- <v-icon color="success">mdi-checkbox-marked-circle</v-icon> -->
                    <div v-if="!isValid">

                        <v-icon v-if="!inverseValidation" style="cursor: help!important" color="error">mdi-close-circle
                        </v-icon>
                        <v-icon v-else style="cursor: help!important" color="success">mdi-checkbox-marked-circle
                        </v-icon>

                    </div>

                    <div v-else-if="isValid">

                        <v-icon v-if="!inverseValidation" style="cursor: help!important" color="success">
                            mdi-checkbox-marked-circle</v-icon>
                        <v-icon v-else style="cursor: help!important" color="error">mdi-close-circle</v-icon>
                    </div>
                    <div v-else />
                </div>

                <div v-if="clearable && inputValue != null && inputValue.length > 0">
                    <v-icon style="margin-top: 2px" v-on:click="clearInput()" size="18">mdi-close</v-icon>
                </div>
            </div>
        </template>
    </v-text-field>

</template>


<script>

export default {
    props: {
        numberBounds: {
            default: null
        },
        id: {
            type: String
        },
        suffix: {
            type: String,
            default: ''
        },
        prefix: {
            type: String,
            default: ''
        },
        alphanumeric: {
            type: Boolean,
            default: false
        },
        inverted: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        },
        password: {
            type: Boolean,
            default: false,
        },
        readonly: {
            type: Boolean,
            default: false
        },

        icon: {
            type: String,
            default: undefined
        },
        clearable: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            required: true
        },
        dark: {
            type: Boolean,
            default: false
        },

        value: {
            required: true
        },

        number: {
            type: Boolean,
            default: false
        },

        validate: {
            type: Boolean,
            default: false
        },
        inverseValidation: {
            type: Boolean,
            default: false
        },

        data: {
            type: Array
        }
    },

    watch: {
        validate: {
            handler() {
                if (this.validate) return this.$emit('valid', this.isValid)
            }, immediate: true
        },
        value: function () {
            this.inputValue = this.value
        },
        inputValue: function () {
            if (this.inputValue != this.value) {
                this.$emit('change', this.inputValue)

                if (this.validate) {
                    this.$emit('valid', this.isValid)
                }
            }
        }
    },

    computed: {
        isValid() {

            if (this.validate && this.data != null) {
                if (this.data.length == 0) return true;
                if (this.number != true) return !this.data.map(device => String(device)).includes(this.inputValue)
                else return !this.data.map(device => parseInt(device)).includes(parseInt(this.inputValue))
            }

            return null;
        }
    },

    data() {
        return {
            showPassword: false,
            inputValue: this.value
        }
    },

    methods: {
        clearInput() {
            this.inputValue = ''
        }
    }


}
</script>
