import { metersToPixel } from "@/models/canvas";
import { Point } from "@/models/project/project";
import { Container, ContainerDimension, containerDimensions } from "@/models/project/shapes";
import { CanvasLine, CanvasPoint, CanvasShape } from "@/models/shapes";
import { CameraController } from "./camera_controller";
import { LineController } from "./line_controller";
import { angleTo, distanceTo, rotate, subtractPoints, translate } from "./point_controller";
import { ShapeController } from "./shape_controller";

export class ContainerController implements ShapeController<Container> {

  shape: Container;

  constructor(shape: Container) {
    this.shape = shape;
  }

  get dimensions(): ContainerDimension {
    return containerDimensions[this.shape.size];
  }

  get points(): Point[] {
    const o = this.shape.origin;
    const w = metersToPixel(this.dimensions.length) / 2;
    const h = metersToPixel(this.dimensions.width) / 2;

    const a = { x: o.x - w, y: o.y - h };
    const b = { x: o.x + w, y: o.y - h };
    const c = { x: o.x + w, y: o.y + h };
    const d = { x: o.x - w, y: o.y + h };

    return [a, b, c, d].map((p) => rotate(p, this.shape.rotation, this.shape.origin));
  }

  get line(): number[] {
    return this.points.flatMap((p) => [p.x, p.y]);
  }

  expell(p: Point): Point {
    p = rotate(p, -this.shape.rotation, this.shape.origin);
    const { x: dx, y: dy } = subtractPoints(p, this.shape.origin);
    let np = { ...p };
    const w = metersToPixel(this.dimensions.length) / 2 + 10;
    const h = metersToPixel(this.dimensions.width) / 2 + 10;
    if (Math.abs(dx) < w && Math.abs(dy) < h) {
      const ddx = Math.abs(w - Math.abs(dx));
      const ddy = Math.abs(h - Math.abs(dy));
      if (ddx < ddy) {
        if (dx < 0) {
          np.x -= (w + dx);
        } else {
          np.x += (w - dx);
        }
      } else {
        if (dy < 0) {
          np.y -= (h + dy);
        } else {
          np.y += (h - dy);
        }
      }
    }
    np = rotate(np, this.shape.rotation, this.shape.origin);
    return np;
  }

  contains(p: Point): boolean {
    p = rotate(p, -this.shape.rotation, this.shape.origin);
    const { x: dx, y: dy } = subtractPoints(p, this.shape.origin);
    const w = metersToPixel(this.dimensions.length) / 2;
    const h = metersToPixel(this.dimensions.width) / 2;
    return Math.abs(dx) < w && Math.abs(dy) < h;
  }

  applyTo(cam: CameraController): void {

    const p = this.points;

    cam.apply(new LineController(p[0], p[1]));
    cam.apply(new LineController(p[1], p[2]));
    cam.apply(new LineController(p[2], p[3]));
    cam.apply(new LineController(p[3], p[0]));
  }

  render(options: { id: string, stroked: boolean, draggable: boolean, focused: boolean }): CanvasShape<any>[] {
    const shapes = [] as CanvasShape<any>[];

    shapes.push(new CanvasLine({
      id: options.id,
      controller: this,
      points: this.line,
      closed: true,
      draggable: options.draggable,
      priority: 2,
      style: {
        fill: '#0000f0aa',
        ...(options.stroked ? {
          stroke: options.focused ? '#cc2027' : 'darkblue',
          strokeWidth: options.draggable ? options.focused ? 3 : 0.5 : 2,
          strokeScaleEnabled: !options.focused,
        } : {})
      },
    }));

    if (options.draggable || options.focused) {
      shapes.push(new CanvasPoint({
        id: `${options.id}-cont`,
        controller: this,
        point: this.shape.origin,
        draggable: options.draggable,
        priority: 5,
        style: {
          fill: 'white',
          stroke: '#00000033',
          strokeWidth: 4,
          strokeScaleEnabled: false,
          fillAfterStrokeEnabled: true,
        },
      }));
    }

    return shapes;
  }

}