import { CameraController } from "@/controllers/camera_controller";
import { CanvasShape, CanvasPoint } from "../shapes";
import { CanvasContext, CanvasTool, StageMouseEvent } from "../tools";

import { PolygonController } from "@/controllers/polygon_controller";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Point } from "../project/project";
import { MoveTool } from "./move_tool";
import { Shape, ShapeType, Tree } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";

export class DrawTreeTool extends CanvasTool {
  onStageScale = MoveTool.scaleCanvas;
  
  nextPoint: Point | null = null;
  radius = 40;

  isShiftPressed = false;
  isAltPressed = false;
  isMouseDown = false;

  getCursor = (): string => this.isShiftPressed ? this.isMouseDown ? 'grabbing' : 'grab' : 'crosshair';

  get tree(): Tree {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const tool = this;
    const options = { radius: this.radius };
    return new Proxy(options, {
      get(target: any, p: string, receiver: any) {
        return target[p];
      },
      set(target: any, p: string | symbol, value: any, receiver: any): boolean {
        target[p] = value;
        if (p == 'radius') {
          tool.radius = value;
        }
        return true;
      },
    });
  }

  onKeyDown = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = true;
      this.onStageDrag = MoveTool.dragCanvas;
    }
    if (e.key.toLowerCase() == 'alt') {
      this.isAltPressed = true;
    }
  }

  onKeyUp = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = false;
      this.onStageDrag = null;
    }
    if (e.key.toLowerCase() == 'alt') {
      this.isAltPressed = false;
    }
  }

  onStageMouseDown = (): void => {
    this.isMouseDown = true;
  }

  onStageMouseUp = (e: StageMouseEvent): void => {
    this.isMouseDown = false;
    if (this.isShiftPressed) return;
    this.controller.addShape({type: ShapeType.tree, origin: e.projectedPoint, radius: this.radius});
    if (!this.isAltPressed) {
      this.selectNext("pointer", `p${this.controller.shapes.length-1}`);
    }
  };

  onStageMouseMoved = (e: StageMouseEvent): void => {
    if (this.nextPoint == null) this.nextPoint = { x: 0, y: 0 };
    this.nextPoint.x = e.projectedPoint.x;
    this.nextPoint.y = e.projectedPoint.y;
  };
  
  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index, 
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`, 
        stroked: false, 
        focused: false, 
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context });
  }

  renderCustomShapes = (): CanvasShape<any>[] => {
    if (this.nextPoint != null) {
      return [
        new CanvasPoint({
          id: "add-tree",
          controller: null,
          point: this.nextPoint,
          priority: 10,
          style: {
            fill: 'white',
            stroke: '#00000033',
            strokeWidth: 4,
            strokeScaleEnabled: false,
            fillAfterStrokeEnabled: true,
          },
        }),
      ];
    } else {
      return [];
    }
  }
}