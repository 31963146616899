import { CameraController } from "@/controllers/camera_controller";
import { addPoints, scale } from "@/controllers/point_controller";
import { PolygonController, PolygonPointController } from "@/controllers/polygon_controller";
import { ViewArcController } from "@/controllers/view_arc_controller";
import { CanvasLine, CanvasPath, CanvasPoint, CanvasShape, CanvasText } from "../shapes";
import { CanvasContext, CanvasTool, ShapeMouseEvent, StageMouseEvent, StageScaleEvent } from "../tools";
import { MoveTool } from "./move_tool";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Shape } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";
import * as konva from "konva/lib/Shape";
import { Point } from "jspdf";
import { Stage } from "konva/lib/Stage";

export interface TextFocus {
  position: Point
  offset: Point
  padding: number
  scale: number
  width: number
  height: number
  fontSize: number
  text: string
  color: string
}
export class SelectTool extends CanvasTool {

  constructor(selected?: string) {
    super();
    this.focused = selected ?? null;

    this.onShapeDrag = SelectTool.dragShape;
  }

  onStageScale = (e: StageScaleEvent): void => {
    const newScale = MoveTool.scaleCanvas.call(this, e);
    if (this.focusedText != null) {
      this.focusedText.offset = { x: e.stage.position().x, y: e.stage.position().y };
      this.focusedText.scale = newScale;
    }
  }

  isShiftPressed = false;
  isMouseDown = false;
  cursor = 'default';
  getCursor = (): string => this.isShiftPressed ? this.isMouseDown ? 'grabbing' : 'grab' : this.cursor;

  onKeyDown = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = true;
      this.onStageDrag = (e: StageMouseEvent): void => {
        MoveTool.dragCanvas.call(this, e);
        if (this.focusedText != null) {
          this.focusedText.offset = { x: e.stage.position().x, y: e.stage.position().y };
          this.focusedText.scale = e.stage.scaleX();
        }
      };
      this.onShapeDrag = null;
    }
  }

  onKeyUp = (e: KeyboardEvent): void => {
    if (e.key.toLowerCase() == 'shift') {
      this.isShiftPressed = false;
      this.onStageDrag = null;
      this.onShapeDrag = SelectTool.dragShape;
    } else if (e.key.toLowerCase() == 'backspace') {
      if (this.focused != null) {
        const index = Number.parseInt(this.focused.substring(1));
        if (this.focused.startsWith('c')) {
          this.controller.removeCamera(this.controller.cameras[index]);
        } else if (this.focused.startsWith('p')) {
          this.controller.removeShape(this.controller.shapes[index]);
        } else if (this.focused.startsWith('l')) {
          this.controller.removeLabel(this.controller.labels[index]);
        }
        this.focused = null;
        this.cursor = 'default';
      }
    }
  }

  onStageMouseDown = (): void => {
    this.isMouseDown = true;
  }

  onStageMouseUp = (): void => {
    this.isMouseDown = false;
  }

  onShapeMouseEnter = (e: ShapeMouseEvent): void => {
    if (e.shape instanceof CanvasLine && e.shape.bounded) {
      this.cursor = 'move';
    } else if (e.shape instanceof CanvasPath) {
      this.cursor = 'move';
    } else if (e.shape instanceof CanvasPoint && e.shape.draggable) {
      if (e.shape.controller instanceof LabelController && e.shape.id.endsWith('size')) {
        this.cursor = 'ns-resize';
      } else {
        this.cursor = 'pointer';
      }
    } else if (e.shape instanceof CanvasText) {
      this.cursor = 'text';
    }
  }

  onShapeMouseLeave = (): void => {
    this.cursor = 'default';
  }

  focused: string | null = null;
  focusedText: TextFocus | null = null;

  onFocusedTextEdited(value: string): void {
    if (this.focused == null || !this.focused.startsWith('l')) return;
    const index = Number.parseInt(this.focused.substring(1));
    const controller = this.controller.labels[index]!;
    controller.label.text = value.replaceAll('\n', '');
    this.focusedText!.text = controller.label.text;
  }

  finishEditing(): void {
    this.focused = null;
    this.focusedText = null;
  }

  onShapeMouseDown = (e: ShapeMouseEvent): void => {
    if (this.isShiftPressed) return;
    if (e.shape.controller instanceof CameraController) {
      this.focused = 'c' + this.controller.cameras.indexOf(e.shape.controller);
      this.focusedText = null;
    } else if (e.shape.controller instanceof PolygonController
      || e.shape.controller instanceof TreeController
      || e.shape.controller instanceof ContainerController) {
      this.focused = 'p' + this.controller.shapes.indexOf(e.shape.controller);
      this.focusedText = null;
    } else if (e.shape.controller instanceof LabelController) {
      const newFocused = 'l' + this.controller.labels.indexOf(e.shape.controller);
      if (newFocused == this.focused && this.focusedText != null) return;
      this.focused = newFocused;
      
      const controller = e.shape.controller;
      const dims = controller.getDimensions(e.canvas.context);
      this.focusedText = {
        position: controller.label.position,
        padding: controller.padding,
        offset: { x: e.stage.position().x, y: e.stage.position().y },
        scale: e.stage.scaleX(),
        width: dims.w,
        height: dims.h,
        fontSize: controller.label.fontSize,
        text: controller.label.text,
        color: controller.color,
      };
    }
  };

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index,
      interactive: true,
      focused: this.focused == `c${index}`,
      draggable: true,
      bounds: this.controller.bounds,
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`,
        stroked: true,
        focused: this.focused == `p${index}`,
        draggable: true,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: true,
        focused: this.focused == `p${index}`,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: true,
        focused: this.focused == `p${index}`,
        stroked: true,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, canvas?: CanvasContext): CanvasShape<any>[] {
    const isFocused = this.focused == `l${index}`;

    if (isFocused && canvas != null) {
      if (this.focusedText != null) {
        const dims = label.getDimensions(canvas.context);
        this.focusedText.width = dims.w;
        this.focusedText.height = dims.h;
        this.focusedText.padding = label.padding;
        this.focusedText.text = label.label.text;
        this.focusedText.fontSize = label.label.fontSize;
        this.focusedText.color = label.color;
      } else {
        console.log("CREATE FOCUS TEXT");
        const dims = label.getDimensions(canvas.context);
        
        this.focusedText = {
          position: label.label.position,
          padding: label.padding,
          offset: { x: canvas.stage.position().x, y: canvas.stage.position().y },
          scale: canvas.stage.scaleX(),
          width: dims.w,
          height: dims.h,
          fontSize: label.label.fontSize,
          text: label.label.text,
          color: label.color,
        };
      
      }
    }

    return label.render({
      id: `label-${index}`, draggable: true,
      focused: isFocused, hideText: isFocused && this.focusedText != null && this.focusedText.text.length > 0, context: canvas?.context
    });
  }

  static dragShape(this: CanvasTool, e: ShapeMouseEvent): void {
    const shape = e.shape;
    if (shape instanceof CanvasPoint) {
      const p = { x: e.target.attrs.x, y: e.target.attrs.y };
      let np = p;

      const controller = shape.controller;
      if (controller instanceof CameraController) {
        controller.camera.origin = p;
        this.controller?.update(controller);
        np = controller.camera.origin;
      } else if (controller instanceof PolygonPointController) {
        controller.point.x = p.x;
        controller.point.y = p.y;
        controller.polygon.update();
        this.controller?.updateAll();
      } else if (controller instanceof ViewArcController) {
        controller.reset();
        controller.dragArc(p);
        this.controller?.update(controller.parent);
      } else if (controller instanceof LabelController) {
        if (shape.id.endsWith('pos')) {
          controller.label.position = p;

          if (this instanceof SelectTool && this.focusedText != null && this.focused != null && this.focused.startsWith('l') && this.focused.endsWith('' + this.controller.labels.indexOf(controller))) {
            this.focusedText.position = p;
          }
        } else if (shape.id.endsWith('size')) {
          const dy = shape.point.y - controller.label.position.y;
          const ndy = p.y - controller.label.position.y;
          const f = ndy / dy;
          controller.label.fontSize = Math.min(500, Math.max(10, Math.round(controller.label.fontSize * f)));

          const dim = controller.getDimensions(e.canvas.context);
          controller.label.position.x = shape.point.x - dim.w / 2;
          
          np = { x: shape.point.x, y: controller.label.position.y + dim.h };

          if (this instanceof SelectTool && this.focusedText != null && this.focused != null && this.focused.startsWith('l') && this.focused.endsWith('' + this.controller.labels.indexOf(controller))) {
            this.focusedText.fontSize = controller.label.fontSize;
            this.focusedText.width = dim.w;
            this.focusedText.height = dim.h;
            this.focusedText.padding = controller.padding;
            this.focusedText.position.x = controller.label.position.x;
          }
        }
      } else if (controller instanceof TreeController) {
        controller.shape.origin = p;
        this.controller?.updateAll();
      } else if (controller instanceof ContainerController) {
        controller.shape.origin = p;
        this.controller?.updateAll();
      }

      e.target.attrs.x = np.x;
      e.target.attrs.y = np.y;
    } else if (shape instanceof CanvasLine) {

      const p = { x: e.event.movementX / e.stage.scaleX(), y: e.event.movementY / e.stage.scaleY() };
      let np = p;

      const controller = shape.controller;
      if (controller instanceof PolygonController) {
        controller.translateBy(p);
        this.controller?.updateAll();
        np = { x: 0, y: 0 };
      } else if (controller instanceof ContainerController) {
        controller.shape.origin = addPoints(controller.shape.origin, p);
        this.controller?.updateAll();
        np = { x: 0, y: 0 };
      } else if (controller instanceof LabelController) {
        controller.label.position = addPoints(controller.label.position, p);

        if (this instanceof SelectTool && this.focusedText != null && this.focused != null && this.focused.startsWith('l') && this.focused.endsWith('' + this.controller.labels.indexOf(controller))) {
          this.focusedText.position = controller.label.position;
        }
        np = { x: 0, y: 0 };
      }

      e.target.attrs.x = np.x;
      e.target.attrs.y = np.y;
    } else if (shape instanceof CanvasPath) {
      const p = { x: e.event.movementX / e.stage.scaleX(), y: e.event.movementY / e.stage.scaleY() };
      let np = p;

      const controller = shape.controller;
      if (controller instanceof CameraController) {
        controller.camera.origin = addPoints(controller.camera.origin, p);
        this.controller?.update(controller);
        np = { x: 0, y: 0 };
      }

      e.target.attrs.x = np.x;
      e.target.attrs.y = np.y;
    }
  }
}