

































































































































































































































import TextButton from "@components/button/TextButton.vue";
import TextInput from "@components/text/TextInput.vue";
import DialogTemplate from "@components/dialog/DialogTemplate.vue";
import { Project } from "@/models/project/project";
import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";
import FurtherInfoAlert from "./FurtherInfoAlert.vue";

@Component({
  components: { DialogTemplate, TextButton, FurtherInfoAlert, TextInput },
})
export default class ProjectDialog extends Vue {
  loadingProjectDelete = false;

  dialog = false;
  @Prop() isReadonly!: boolean;
  isOptPressed = false;

  @Prop() project!: Project;

  created(): void {
    document.addEventListener("keydown", (event: KeyboardEvent) => {
      if (this.dialog == true && event.keyCode == 18) this.isOptPressed = true;
    });
    document.addEventListener("keyup", (event: KeyboardEvent) => {
      if (this.dialog == true && event.keyCode == 18) this.isOptPressed = false;
    });
  }

  get isOwner(): boolean {
    return (
      this.project.members[this.$firebase.auth.uid as string].role == "owner"
    );
  }

  async deleteProject(): Promise<void> {
    if (this.project == null) return;
    if (this.isOwner == true) {
      this.loadingProjectDelete = true;

      await this.$project.deleteProject(this.project.id as string);
      this.loadingProjectDelete = false;
    } else {
      this.$router
        .replace({ path: this.$route.path, query: { remove: "true" } })
        .catch(() => {
          return;
        });

      this.$nextTick(async () => {
        let projectHandle = this.project;

        delete projectHandle.members[this.$firebase.auth.uid as string];

        await this.$project.updateProject(projectHandle);

        this.$router.push({ name: "overview" });
      });
    }
  }

  open(): void {
    this.dialog = true;
  }
}
