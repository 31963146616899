import { CameraController } from "@/controllers/camera_controller";
import { PolygonController } from "@/controllers/polygon_controller";
import { CanvasShape } from "../shapes";
import { CanvasContext, CanvasTool, StageMouseEvent } from "../tools";
import { MoveTool } from "./move_tool";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Shape } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { Label } from "../project/project";
import { SceneContext } from "konva/lib/Context";
export class LabelTool extends CanvasTool {

  text = "";
  fontSize = 20;

  onStageScale = MoveTool.scaleCanvas;

  getCursor = (): string => 'text';

  get label(): Label {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const tool = this;
    const options = { text: this.text, fontSize: this.fontSize };
    return new Proxy(options, {
      get(target: any, p: string, receiver: any) {
        return target[p];
      },
      set(target: any, p: string | symbol, value: any, receiver: any): boolean {
        target[p] = value;
        if (p == 'text') {
          tool.text = value;
        } else if (p == 'fontSize') {
          tool.fontSize = value;
        }
        return true;
      },
    });
  }

  onStageMouseUp = (e: StageMouseEvent): void => {
    const p = e.projectedPoint;

    this.controller.addLabel({ text: this.text, fontSize: this.fontSize, position: p });
    this.selectNext("pointer", `l${this.controller.labels.length-1}`);
  };

  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index, 
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`, 
        stroked: false, 
        focused: false, 
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context });
  }
}