import { CameraConfig, CameraController } from "@/controllers/camera_controller";

import { CanvasShape, CanvasPoint } from "../shapes";
import { CanvasContext, CanvasTool, StageMouseEvent } from "../tools";

import deviceConfig from "../../../device.config.json";
import typesConfig from "../../../config.json";
import { PolygonController } from "@/controllers/polygon_controller";
import { LabelController } from "@/controllers/label_controller";
import { ShapeController } from "@/controllers/shape_controller";
import { TreeController } from "@/controllers/tree_controller";
import { Point } from "../project/project";
import { Shape } from "../project/shapes";
import { ContainerController } from "@/controllers/container_controller";
import { SceneContext } from "konva/lib/Context";

export abstract class DrawCameraTool extends CanvasTool {

  abstract typeId: string;
  
  get config(): CameraConfig | undefined {
    return this.typeId in deviceConfig ? deviceConfig[this.typeId as keyof (typeof deviceConfig)] : undefined
  }

  static forId(id: string): new () => DrawCameraTool {
    return class IdCameraTool extends DrawCameraTool {
      typeId = id;
    };
  }

  static forType(id: keyof typeof typesConfig): new () => TypesCameraTool {
    return class TypeCameraTool extends TypesCameraTool {
      constructor() {
        super(id);
      }
    };
  }

  nextPoint: Point | null = null;

  getCursor = (): string => 'crosshair';

  onStageMouseUp = (e: StageMouseEvent): void => {
    if (this.config == undefined) {
      this.controller.addCamera(CameraController.empty(e.projectedPoint, this.typeId));
    } else {
      this.controller.addCamera(CameraController.fromConfig(e.projectedPoint, this.config, this.typeId));
    }
    this.selectNext("pointer", `c${this.controller.cameras.length-1}`);
  };

  onStageMouseMoved = (e: StageMouseEvent): void => {
    if (this.nextPoint == null) this.nextPoint = { x: 0, y: 0 };
    this.nextPoint.x = e.projectedPoint.x;
    this.nextPoint.y = e.projectedPoint.y;
  };
  
  renderCamera(camera: CameraController, index: number): CanvasShape<any>[] {
    return camera.render({
      index: index, 
      interactive: false,
      focused: false,
      draggable: false,
      bounds: this.controller.bounds
    });
  }

  renderShape(shape: ShapeController<Shape>, index: number): CanvasShape<any>[] {
    if (shape instanceof PolygonController) {
      return shape.render({
        id: `poly-${index}`, 
        stroked: false, 
        focused: false, 
        draggable: false,
      });
    } else if (shape instanceof TreeController) {
      return shape.render({
        id: `tree-${index}`,
        draggable: false,
        focused: false,
      });
    } else if (shape instanceof ContainerController) {
      return shape.render({
        id: `container-${index}`,
        draggable: false,
        focused: false,
        stroked: false,
      });
    }
    return [];
  }

  renderLabel(label: LabelController, index: number, context?: CanvasContext): CanvasShape<any>[] {
    return label.render({ id: `label-${index}`, draggable: false, focused: false, context: context?.context });
  }

  renderCustomShapes = (): CanvasShape<any>[] => {
    if (this.nextPoint != null) {
      return [
        new CanvasPoint({
          id: "add-cam",
          controller: null,
          point: this.nextPoint,
          priority: 10,
          style: {
            fill: 'white',
            stroke: '#00000033',
            strokeWidth: 4,
            strokeScaleEnabled: false,
            fillAfterStrokeEnabled: true,
          },
        }),
      ];
    } else {
      return [];
    }
  }
}

export abstract class TypesCameraTool extends DrawCameraTool {
  
  typeId!: string;
  types!: {[key: string]: any};

  constructor(t: keyof typeof typesConfig) {
    super();
    this.types = typesConfig[t].types;
    this.typeId = Object.keys(this.types)[0];
  }

  selectType(id: string): void {
    this.typeId = id;
  }
}