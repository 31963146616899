

import { Point } from "@/models/project/project";
import { Shape, Polygon, Tree, ShapeType } from "@/models/project/shapes";
import { CanvasPoint, CanvasShape } from "@/models/shapes";
import { CameraController } from "./camera_controller";
import { ContainerController } from "./container_controller";
import { LineController } from "./line_controller";
import { angleTo, distanceTo, translate } from "./point_controller";
import { PolygonController } from "./polygon_controller";
import { TreeController } from "./tree_controller";

export abstract class ShapeController<T extends Shape> {
  static from(shape: Shape): ShapeController<Shape> {
    // for backwards compatibility
    if (!('type' in (shape as any))) {
      if ('points' in shape) {
        shape.type = ShapeType.polygon;
      } else if ('radius' in shape) {
        shape.type = ShapeType.tree;
      } else {
        throw 'Unknown Shape';
      }
    }

    switch (shape.type) {
      case ShapeType.polygon: return new PolygonController(shape);
      case ShapeType.tree: return new TreeController(shape);
      case ShapeType.container: return new ContainerController(shape);
    }
  }

  abstract shape: T;

  abstract expell(p: Point): Point;
  abstract contains(p: Point): boolean;
  abstract applyTo(c: CameraController): void;
}
