



























































































































































































































import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

import TextButton from "@components/button/TextButton.vue";
import IconButton from "@components/button/IconButton.vue";
import TimelineElement from "./TimelineElement.vue";
import { Project, StatusLog } from "@/models/project/project";

@Component({
  components: {
    TextButton,
    IconButton,
    TimelineElement,
  },
})
export default class InfoToolbar extends Vue {
  @Prop() project!: Project;
  @Prop() hasValidArmingCameras!: boolean;

  expandStatusMenu = false;

  get status(): StatusLog {
    return this.statusLog[0];
  }

  get statusLog(): StatusLog[] {
    return [...this.project.statusLog].reverse();
  }

  get isReviewer(): boolean {
    if (this.$firebase.auth == null || this.$firebase.auth.uid == null)
      return false;
    return this.project &&
      this.project.members[this.$firebase.auth.uid] != null &&
      this.project.members[this.$firebase.auth.uid].role == "reviewer"
      ? true
      : false;
  }

  async changeStatus(nextStatus: number): Promise<void> {
    this.$emit("change:status", nextStatus);
  }
}
