
import { Label } from "@/models/project/project";
import { CanvasLine, CanvasPoint, CanvasShape, CanvasText } from "@/models/shapes";
import { Context, SceneContext } from "konva/lib/Context";
import { addPoints } from "./point_controller";

export class LabelController {

  label: Label;

  constructor(label: Label) {
    this.label = label;
  }

  get text(): string {
    if (this.label.text.length == 0) {
      return 'Type something';
    } else {
      return this.label.text;
    }
  }

  get color(): string {
    if (this.label.text.length == 0) {
      return '#aaaa';
    } else {
      return this.label.color ?? 'black';
    }
  }

  rect(w: number, h: number): number[] {
    const p = this.label.position;
    const f = 1;
    return [p.x, p.y, p.x + w * f, p.y, p.x + w * f, p.y + h * f, p.x, p.y + h * f];
  }

  get padding(): number {
    return this.label.fontSize / 10;
  }

  getDimensions(context: SceneContext): { w: number, h: number } {
    const font = (context as any).font;
    context!.setAttr('font', `bold ${this.label.fontSize}px Lato`);
    const m = context.measureText(this.text);
    context!.setAttr('font', font);

    return {
      w: m.width + this.padding * 2,
      h: m.fontBoundingBoxAscent + m.fontBoundingBoxDescent
    };
  }

  render(options: { id: string, draggable: boolean, focused: boolean, hideText?: boolean, context?: SceneContext }): CanvasShape<any>[] {

    const dim = options.context != null ? this.getDimensions(options.context!) : null;
    const hasBox = (options.draggable || options.focused) && dim != null;

    const shapes: CanvasShape<any>[] = [];

    if (options.hideText != true) {
      shapes.push(new CanvasText({
        id: options.id,
        controller: this,
        text: this.text,
        fontSize: this.label.fontSize,
        position: addPoints(this.label.position, { x: this.padding, y: this.padding }),
        color: this.color,
        priority: 6,
      }));
    }

    if (options.draggable) {
      shapes.push(new CanvasPoint({
        id: `${options.id}-pos`,
        controller: this,
        point: this.label.position,
        draggable: true,
        priority: 7,
        style: {
          fill: 'white',
          stroke: '#00000033',
          strokeWidth: 4,
          strokeScaleEnabled: false,
          fillAfterStrokeEnabled: true,
        },
      }));
    }

    if (hasBox && options.draggable) {
        shapes.push(new CanvasPoint({
          id: `${options.id}-size`,
          controller: this,
          point: addPoints(this.label.position, { x: dim!.w / 2, y: dim!.h }),
          draggable: true,
          priority: 7,
          style: {
            fill: 'white',
            stroke: '#00000033',
            strokeWidth: 4,
            strokeScaleEnabled: false,
            fillAfterStrokeEnabled: true,
          },
        }));
      }
    

    if (dim != null && (hasBox || this.label.highlightColor != null)) {
      shapes.push(new CanvasLine({
        id: `${options.id}-rect`,
        controller: this,
        points: this.rect(dim.w, dim.h),
        priority: 5,
        closed: true,
        draggable: options.draggable,
        style: {
          ...(this.label.highlightColor != null ? {fill: this.label.highlightColor + '88'} : {}),
          ...(hasBox ? {
          stroke: '#cc2027',
          strokeWidth: options.draggable ? options.focused ? 3 : 1 : 2,
          strokeScaleEnabled: false,
          lineCap: 'round',
          lineJoin: 'round',
          } : {}),
        },
      }));
    }
    

    return shapes;
  }

}
