
import { metersToPixel } from "@/models/canvas";
import { CanvasCircle, CanvasClippedPath, CanvasLine, CanvasPath, CanvasPoint, CanvasShape } from "@/models/shapes";
import { Camera } from "../models/project/camera";
import { Shape } from "../models/project/shapes";
import { LineController } from "./line_controller";
import { PolygonController } from "./polygon_controller";
import { ShapeController } from "./shape_controller";
import { ViewArcController } from "./view_arc_controller";

import deviceConfig from "../../device.config.json";
import { toRad } from "./point_controller";
import { Point } from "@/models/project/project";

export interface CameraConfig {
  color: string;
  id: number;
  arcs: ArcConfig[];
}

export interface ArcConfig {
  heading: number;
  aperture: number;

  innerRadius: number;
  outerRadius: number;

  canRotate: boolean;

  zoomTo?: {
    aperture: number;
    innerRadius: number;
    outerRadius: number;
  }
}

export class CameraController {

  camera: Camera;
  enabled: boolean;
  controllers: ViewArcController[];

  constructor(camera: Camera) {
    this.camera = camera;
    this.enabled = true;
    this.controllers = camera.arcs.map((a) => new ViewArcController(a, this));
  }

  static empty(origin: Point, type: string): Camera {
    return { origin, type, arcs: [] };
  }

  static fromConfig(origin: Point, config: CameraConfig, type: string): Camera {
    return {
      type,
      origin,
      arcs: config.arcs.map((a) => ({
        heading: a.heading,
        zoom: 0,
        zoomStart: {
          aperture: a.aperture,
          innerRadius: metersToPixel(a.innerRadius),
          outerRadius: metersToPixel(a.outerRadius),
        },
        ...(a.zoomTo != null ? {zoomEnd: {
          aperture: a.zoomTo.aperture,
          innerRadius: metersToPixel(a.zoomTo.innerRadius),
          outerRadius: metersToPixel(a.zoomTo.outerRadius),
        }} : {}),
        canRotate: a.canRotate,
      })),
    };
  }

  reset(): void {
    this.enabled = true;
    for (const controller of this.controllers) {
      controller.reset();
    }
  }

  apply(line: LineController): void {
    for (const controller of this.controllers) {
      controller.apply(line);
    }
  }

  computePath(): void {
    for (const controller of this.controllers) {
      controller.computePath();
    }
  }

  computeShadowPath(): void {
    for (const controller of this.controllers) {
      controller.computeShadowPath();
    }
  }

  tryExpell(shapes: ShapeController<Shape>[]): void {
    let newOrigin = this.camera.origin;
    for (const shape of shapes) {
      newOrigin = shape.expell(newOrigin);
    }
    if (shapes.some((p) => p.contains(newOrigin))) {
      if (!this.enabled) {
        return;
      }
      this.enabled = false;
    } else {
      const pEqual = this.camera.origin.x == newOrigin.x && this.camera.origin.y == newOrigin.y;
      if (this.enabled && pEqual) {
        return;
      }
      this.camera.origin.x = newOrigin.x;
      this.camera.origin.y = newOrigin.y;
      this.enabled = true;
    }
  }

  render(options: { index: number, interactive: boolean, focused: boolean, draggable: boolean, bounds: PolygonController | undefined }): CanvasShape<any>[] {
    const shapes = [] as CanvasShape<any>[];

    const id = `camera-${options.index}`;

    if (this.enabled) {
      for (const i in this.controllers) {
        const arc = this.controllers[i];

        if (options.bounds != null) {
          shapes.push(new CanvasClippedPath({
            id: `${id}-${i}-bounds-path`,
            path: arc.outOfBoundsPath,
            points: options.bounds.line(),
            bounded: false,
            priority: 3,
          }));
        }

        shapes.push(new CanvasPath({
          id: `${id}-${i}-path`,
          controller: this,
          path: arc.path,
          priority: 4,
          style: { 
            fill: '#cc202799',
            ...(options.interactive ? {
              stroke: '#cc2027',
              strokeWidth: options.draggable ? options.focused ? 3 : 0.5 : 2,
              strokeScaleEnabled: false,
              lineCap: 'round',
              lineJoin: 'round',
            } : {}),
          },
        }));

        if (options.draggable && options.focused && arc.arc.canRotate) {
            shapes.push(new CanvasLine({
              id: `${id}-${i}-arc-dashed`,
              controller: null,
              points: [
                arc.origin.x,
                arc.origin.y,
                arc.origin.x + Math.cos(toRad(arc.arc.heading)) * (arc.innerRadius + arc.outerRadius) / 2,
                arc.origin.y + Math.sin(toRad(arc.arc.heading)) * (arc.innerRadius + arc.outerRadius) / 2,
              ],
              priority: 5,
              closed: false,
              style: {
                stroke: '#bbb9',
                strokeWidth: 2,
                strokeScaleEnabled: false,
                lineCap: 'round',
                lineJoin: 'round',
                dash: [10, 10],
              }
            }));
  
            shapes.push(new CanvasPoint({
              id: `${id}-${i}-arc`,
              controller: arc,
              point: {
                x: arc.origin.x + Math.cos(toRad(arc.arc.heading)) * (arc.innerRadius + arc.outerRadius) / 2,
                y: arc.origin.y + Math.sin(toRad(arc.arc.heading)) * (arc.innerRadius + arc.outerRadius) / 2,
              },
              draggable: true,
              priority: 8,
              style: {
                fill: 'white',
                stroke: '#00000033',
                strokeWidth: 4,
                strokeScaleEnabled: false,
                fillAfterStrokeEnabled: true,
              },
            }));
          
        }
      }
    }

    if (options.interactive) {
      if (this.controllers.length == 0) {
        shapes.push(new CanvasPoint({
          id: `${id}-point`,
          controller: this,
          point: this.camera.origin,
          draggable: options.draggable,
          priority: 7,
          radius: 8,
          style: {
            fill: '#cc202799',
            stroke: '#cc2027',
            strokeWidth: options.draggable ? options.focused ? 3 : 0.5 : 2,
            strokeScaleEnabled: false,
          },
        }));
      }
  
      shapes.push(new CanvasPoint({
        id: `${id}`,
        controller: this,
        point: this.camera.origin,
        draggable: options.draggable,
        priority: 7,
        style: {
          fill: 'white',
          stroke: '#00000033',
          strokeWidth: 4,
          strokeScaleEnabled: false,
          fillAfterStrokeEnabled: true,
        },
      }));
    } else {
      shapes.push(new CanvasCircle({
        id: `${id}`,
        point: this.camera.origin,
        text: options.index.toString(),
        color: deviceConfig[this.camera.type as keyof (typeof deviceConfig)]?.color,
        priority: 9,
      }));
    }

    return shapes;
  }
}

export class RotateArcController {

  camera: CameraController;
  arc: ViewArcController;

  constructor(camera: CameraController,
    arc: ViewArcController) {
    this.camera = camera;
    this.arc = arc;
  }
}