
import { Point } from "../models/project/project";
import { LineController } from "./line_controller";

export function zeroPoint(): Point { return { x: 0, y: 0 } }

export function addPoints(a: Point, b: Point): Point {
  return { x: a.x + b.x, y: a.y + b.y };
}

export function subtractPoints(a: Point, b: Point): Point {
  return { x: a.x - b.x, y: a.y - b.y };
}

export function normalizePoint(p: Point): Point {
  const l = distanceTo(p, zeroPoint());
  return { x: p.x / l, y: p.y / l };
}

export function scale(p: Point, s: number): Point {
  return { x: p.x * s, y: p.y * s };
}

export function dot(a: Point, b: Point): number {
  return a.x * b.x + a.y * b.y;
}

export function distanceTo(a: Point, b: Point): number {
  const dx = b.x - a.x;
  const dy = b.y - a.y;
  return Math.sqrt(dx * dx + dy * dy);
}

export function angleTo(a: Point, b: Point): number {
  return toDeg(Math.atan2(b.y - a.y, b.x - a.x));
}

export function projectOnto(p: Point, line: LineController): Point {
  const l = line.toFormal();

  const lsqr = l.dx * l.dx + l.dy * l.dy;
  if (lsqr == 0) return { x: line.start.x, y: line.start.y };

  let t = ((p.x - line.start.x) * (l.dx) + (p.y - line.start.y) * l.dy) / lsqr;
  t = Math.max(0, Math.min(1, t));

  return {
    x: line.start.x + t * l.dx,
    y: line.start.y + t * l.dy,
  };
}

export function translate(p: Point, angle: number, distance: number): Point {
  return {
    x: p.x + distance * Math.cos(toRad(angle)),
    y: p.y + distance * Math.sin(toRad(angle)),
  };
}

export function rotate(p: Point, angle: number, origin: Point): Point {
  p = subtractPoints(p, origin);
  const a = toRad(angle);
  const c = Math.cos(a), s = Math.sin(a);
  p = {x: p.x * c - p.y * s, y: p.x * s + p.y * c};
  return addPoints(p, origin);
}

export function toRad(degrees: number): number {
  return degrees * Math.PI / 180;
}

export function toDeg(rad: number): number {
  return rad * 180 / Math.PI;
}

export function edgeToLine(origin: Point, angle: number, radius: number): LineController {
  return new LineController(origin, translate(origin, angle, radius));
}

export function normalizeAngle(angle: number, startAngle: number): number {
  const ep = Number.EPSILON * 1e10;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    if (Math.abs(angle - startAngle) < ep) {
      return startAngle;
    }
    if (angle < startAngle - ep) {
      angle += 360;
    } else if (angle > startAngle + 360 - ep) {
      angle -= 360;
    } else {
      break;
    }
  }

  return angle;
}