




























































import TextButton from "@components/button/TextButton.vue";
import TextInput from "@components/text/TextInput.vue";

import { RawLocation } from "vue-router";
import Vue from "vue";
import { Component } from "vue-property-decorator";

@Component({
  components: {
    TextButton,
    TextInput
  },
})


export default class LoginView extends Vue {

  loading = false;

  user = {
    mail: '',
    password: ''
  };

  error = {
    text: "",
    status: false
  }


  get isValidMail(): boolean {
    return String(this.user.mail)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) ? true : false
  }


  async sendResetMail(): Promise<void> {
    
    try {
      await this.$firebase.auth.forgotMail(this.user.mail);
      this.$toast.info('E-Mail zum Zurücksetzen des Passwort gesendet.')
    } catch {
      this.$toast.error('E-Mail konnte nicht zugestellt werden.')
    }

  }
  /**
   * login()
   * 
   * tries to login user based on mail and password to firebase. 
   * If successfull user is forward to aimed route or to overview page
   * 
   */
  async login(): Promise<void> {

    // reset error and set loading
    this.loading = true;
    this.error.status = false;


    try {
      await this.$firebase.auth.login(this.user.mail, this.user.password); // login user

      // check if forward route is provided; else to overview page
      if (this.$route.query != null && this.$route.query.route != null) {
        this.$router.push(({ path: this.$route.query.route } as RawLocation)).catch(() => true)
      } else {
        this.$router.push("overview");
      }


    } catch (error: any) {
      switch (error.message) {
        case "unknown-error":
          this.error.text = "Fehler bei der Anmeldung";
          break;

        default:
          this.error.text = "Inkorrekte Mail und/oder Passwort angegeben";
          break;
      }

      this.error.status = true;
      this.loading = false;
    }
  }


}
