







import Vue from "vue";
import { Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
  },
})
export default class DetailsPanel extends Vue {
  
}
