var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-timeline-item',{staticClass:"elevation-0",attrs:{"icon":_vm.active
      ? _vm.status && _vm.status.status == 7
        ? 'mdi-check'
        : 'mdi-pencil'
      : null,"color":_vm.active == true
      ? _vm.status && _vm.status.status == 7
        ? 'primary'
        : 'grey'
      : 'primary',"small":"","fill-dot":""}},[_c('div',{class:_vm.active ? 'pb-3' : ''},[_c('v-row',{staticClass:"ma-0 mr-5",attrs:{"align":"center"}},[_c('div',[_c('div',{staticStyle:{"height":"20px"}},[(_vm.status.status == 1)?_c('b',[_vm._v("Projekterstellung")]):(_vm.status.status == 2)?_c('b',[_vm._v("Projektentwurf")]):(_vm.status.status == 3)?_c('b',[_vm._v("Projektierung")]):(_vm.status.status == 4)?_c('b',[_vm._v("Techn. Überprüfung")]):(_vm.status.status == 5)?_c('b',[_vm._v("Überarbeitung")]):(_vm.status.status == 6)?_c('b',[_vm._v("Projektfreigabe")]):(_vm.status.status == 7)?_c('b',[_vm._v("Projekt abgeschlossen")]):_vm._e()]),_c('div',{staticStyle:{"font-size":"14px","color":"rgba(0, 0, 0, 0.5)"}},[_vm._v(" "+_vm._s(new Date(_vm.status.timestamp).toLocaleTimeString("de-de", { year: "numeric", month: "long", day: "numeric", hour: "2-digit", minute: "2-digit", }))+" Uhr ")])]),_c('v-spacer'),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.status.status == 3 || _vm.status.status == 7) &&
          _vm.status.downloadUrl.length > 1
        ),expression:"\n          (status.status == 3 || status.status == 7) &&\n          status.downloadUrl.length > 1\n        "}],staticClass:"mr-1",attrs:{"loading":_vm.loadingLink,"icon":"mdi-link-variant"},on:{"click":function($event){return _vm.copy2Clipboard()}}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(
          (_vm.status.status == 3 || _vm.status.status == 7) &&
          _vm.status.downloadUrl.length > 1
        ),expression:"\n          (status.status == 3 || status.status == 7) &&\n          status.downloadUrl.length > 1\n        "}],staticClass:"mr-1",attrs:{"loading":_vm.loadingDownload,"icon":"mdi-download"},on:{"click":function($event){return _vm.download(_vm.status.downloadUrl)}}}),_c('IconButton',{directives:[{name:"show",rawName:"v-show",value:(!_vm.active && _vm.status.comment.length > 0),expression:"!active && status.comment.length > 0"}],attrs:{"disabled":_vm.status.comment == null || _vm.status.comment.length == 0,"color":_vm.showStatusComment ? 'primary' : '',"icon":"mdi-comment-processing"},on:{"click":function($event){_vm.showStatusComment = !_vm.showStatusComment}}})],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showStatusComment || (_vm.active && _vm.status.comment.length > 0)),expression:"showStatusComment || (active && status.comment.length > 0)"}],staticClass:"mr-5 pb-1 pt-1 mt-2",staticStyle:{"border-radius":"10px","background-color":"#f0f0f0","border":"1px solid #e0e0e0","max-height":"120px","overflow":"hidden !important"}},[_c('div',{staticClass:"font-weight-bold black--text pl-3",staticStyle:{"font-size":"14px"}},[_vm._v(" Anmerkungen: ")]),_c('v-divider',{staticClass:"mt-1",staticStyle:{"opacity":"0.4"}}),_c('div',{staticStyle:{"font-size":"14px","max-height":"88px","overflow-y":"auto","line-height":"1.2 !important","color":"rgba(0, 0, 0, 0.6)"}},[_c('div',{staticClass:"pr-2 pl-3 pb-2 pt-1"},[_vm._v(_vm._s(_vm.status.comment))])])],1),_c('v-row',{directives:[{name:"show",rawName:"v-show",value:(_vm.status.mail != null),expression:"status.mail != null"}],staticClass:"ma-0 pt-1 grey--text",staticStyle:{"font-size":"13px"}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"15","color":"grey"}},[_vm._v("mdi-account-circle")]),_c('b',[_vm._v(_vm._s(_vm.status.mail))])],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }