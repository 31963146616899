


















































































































































































































import Component from "vue-class-component";
import konva from "konva";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import { CanvasShape } from "@/models/shapes";
import { Context, SceneContext } from "konva/lib/Context";
import { CanvasTool, CanvasContext } from "@/models/tools";
import { Point } from "@/models/project/project";
import { PolygonController } from "@/controllers/polygon_controller";

interface RawStageScaleEvent {
  evt: WheelEvent;
  currentTarget: konva.Stage;
}

interface RawStageMouseEvent {
  evt: MouseEvent;
  currentTarget: konva.Stage;
}

interface RawShapeMouseEvent {
  target: konva.Shape;
  evt: MouseEvent;
}

export interface CanvasImage {
  width: number;
  height: number;
  url: string;
}

@Component
export default class Canvas extends Vue implements CanvasContext {
  @Prop()
  readonly image!: CanvasImage | null;

  @Prop()
  readonly shapes!: CanvasShape<any>[];

  @Prop()
  readonly tool!: CanvasTool;

  configKonva = {
    width: window.innerWidth,
    height: window.innerHeight,
    scaleX: 1,
    scaleY: 1,
  };

  background = {
    x: 0,
    y: 0,
    width: window.innerWidth,
    height: window.innerHeight,
    fillPatternImage: new Image(),
    fillPatternOffset: { x: 0, y: 0 },
    listening: false,
  };

  stripePatternImage = new Image();

  curImage: CanvasImage | null = null;
  planImage = null as HTMLImageElement | null;

  static planPadding = 100;

  get maxZoom(): number {
    return Math.max(4, this.minZoom * 4);
  }

  get minZoom(): number {
    if (this.image == null) return 1;
    return this.fitZoom(this.image.width, this.image.height);
  }

  fitZoom(w: number, h: number): number {
    var minX = (window.innerWidth - Canvas.planPadding) / w;
    var minY = (window.innerHeight - Canvas.planPadding) / h;
    return Math.min(minX, minY);
  }

  get stage(): konva.Stage {
    return (this.$refs.stage as any).getStage() as konva.Stage;
  }

  get context(): SceneContext {
    return (this.$refs.shapes as any).getNode().canvas.context;
  }

  created(): void {
    const img = new Image();
    img.onload = () => (this.background.fillPatternImage = img);
    img.src = require("@/assets/grid.png");

    const img2 = new Image();
    img2.onload = () => (this.stripePatternImage = img2);
    img2.src = require("@/assets/stripes.jpeg");

    window.onresize = () => {
      this.configKonva.width = window.innerWidth;
      this.configKonva.height = window.innerHeight;
      this.updateBackground();
    };
  }

  mounted(): void {
    const elem: any = this.$refs.background;
    var nativeCtx = elem.getNode().getContext()._context;
    nativeCtx.webkitImageSmoothingEnabled = false;
    nativeCtx.mozImageSmoothingEnabled = false;
    nativeCtx.imageSmoothingEnabled = false;

    this.fitImageToWindow();
  }

  projectStage(): konva.Stage | void {
    if (this.$refs.stage == null) return;
    return (this.$refs.stage as any).getStage() as konva.Stage;
  }

  @Watch("image", { immediate: true, deep: true })
  onImageChanged(image: CanvasImage | null): void {
    if (image != null) {
      if (
        this.curImage == null ||
        this.curImage.url != image.url ||
        this.curImage.width != image.width ||
        this.curImage.height != image.height
      ) {
        const img = new Image();
        img.onload = () => {
          this.planImage = img;
          this.$emit("loaded", true);
        };

        img.onerror = () => {
          this.$emit("loaded", false);
        };
        img.src = image.url;
        img.crossOrigin = "Anonymous";

        this.fitImageToWindow();
      }
    }
    this.curImage = image != null ? { ...image } : null;
  }

  fitMode: "window" | "border" | null = null;

  fitImageToWindow(): void {
    const stageHandle = this.projectStage();

    if (this.image == null) return;
    if (stageHandle == null) return;

    if (this.fitMode != "window") {
      this.configKonva.scaleX = this.minZoom;
      this.configKonva.scaleY = this.minZoom;
      stageHandle.position({
        x: (window.innerWidth - this.configKonva.scaleX * this.image.width) / 2,
        y:
          (window.innerHeight - this.configKonva.scaleY * this.image.height) /
          2,
      });

      this.updateBackground();
      this.fitMode = "window";
    } else {
      var border = this.shapes
        .map((s) => s.controller)
        .find((s) => s instanceof PolygonController && !s.shape.bounded) as
        | PolygonController
        | undefined;
      if (border == null) {
        return;
      }

      let minX = Number.MAX_VALUE,
        minY = Number.MAX_VALUE,
        maxX = Number.MIN_VALUE,
        maxY = Number.MIN_VALUE;

      for (var point of border.shape.points) {
        minX = Math.min(minX, point.x);
        maxX = Math.max(maxX, point.x);
        minY = Math.min(minY, point.y);
        maxY = Math.max(maxY, point.y);
      }

      var zoom = this.fitZoom(maxX - minX, maxY - minY);

      this.configKonva.scaleX = zoom;
      this.configKonva.scaleY = zoom;

      stageHandle.position({
        x:
          -minX * zoom +
          (window.innerWidth - this.configKonva.scaleX * (maxX - minX)) / 2,
        y:
          -minY * zoom +
          (window.innerHeight - this.configKonva.scaleY * (maxY - minY)) / 2,
      });

      this.updateBackground();
      this.fitMode = "border";
    }
  }

  updateBackground(): void {
    const stageHandle = this.projectStage();
    if (stageHandle == null) return;

    this.background.x = -stageHandle.position().x / this.configKonva.scaleX;
    this.background.y = -stageHandle.position().y / this.configKonva.scaleY;
    this.background.width = stageHandle.width() / this.configKonva.scaleX;
    this.background.height = stageHandle.height() / this.configKonva.scaleY;
    this.background.fillPatternOffset = {
      x: this.background.x,
      y: this.background.y,
    };

    this.fitMode = null;
  }

  get toolStageDraggable(): boolean {
    return this.tool?.onStageDrag != null;
  }

  get toolShapeDraggable(): boolean {
    return this.tool?.onShapeDrag != null;
  }

  getToolCursor(): string | null {
    return this.tool?.getCursor?.call(this.tool!, null) ?? null;
  }

  projectEventLocation(e: RawStageMouseEvent): Point {
    var stage = e.currentTarget;
    return {
      x: (e.evt.x - stage.position().x) / stage.scaleX(),
      y: (e.evt.y - stage.position().y) / stage.scaleY(),
    };
  }

  async canvasSnapshot(): Promise<any | undefined> {
    if (this.$refs.stage == null) return;
    const stage = (this.$refs.stage as any).getStage();

    var snapshotImage = await stage.toDataURL({
      pixelRatio: 2,
    });

    var snapshotDownloadImage = await stage.toDataURL({
      pixelRatio: 1,
    });

    return {
      data: snapshotImage,
      download: snapshotDownloadImage,
      scale: stage.scaleX(),
      stage: stage,
    };
  }

  handleMouseMove(e: RawStageMouseEvent): void {
    this.tool?.onStageMouseMoved?.call(this.tool, {
      event: e.evt,
      stage: e.currentTarget,
      canvas: this,
      projectedPoint: this.projectEventLocation(e),
    });
  }

  handleMouseDown(e: RawStageMouseEvent): void {
    this.tool?.onStageMouseDown?.call(this.tool, {
      event: e.evt,
      stage: e.currentTarget,
      canvas: this,
      projectedPoint: this.projectEventLocation(e),
    });
  }

  handleMouseUp(e: RawStageMouseEvent): void {
    this.tool?.onStageMouseUp?.call(this.tool, {
      event: e.evt,
      stage: e.currentTarget,
      canvas: this,
      projectedPoint: this.projectEventLocation(e),
    });
  }

  handleShapeMouseEnter(shape: CanvasShape<any>, e: RawShapeMouseEvent): void {
    this.tool?.onShapeMouseEnter?.call(this.tool, {
      event: e.evt,
      target: e.target,
      shape: shape,
      canvas: this,
      stage: this.getStage(e.target),
    });
  }

  handleShapeMouseLeave(shape: CanvasShape<any>, e: RawShapeMouseEvent): void {
    this.tool?.onShapeMouseLeave?.call(this.tool, {
      event: e.evt,
      target: e.target,
      shape: shape,
      canvas: this,
      stage: this.getStage(e.target),
    });
  }

  handleShapeMouseDown(shape: CanvasShape<any>, e: RawShapeMouseEvent): void {
    this.tool?.onShapeMouseDown?.call(this.tool, {
      event: e.evt,
      target: e.target,
      shape: shape,
      canvas: this,
      stage: this.getStage(e.target),
    });
  }

  onScale(e: RawStageScaleEvent): void {
    this.tool?.onStageScale?.call(this.tool, {
      event: e.evt,
      stage: e.currentTarget,
      canvas: this,
    });
  }

  handleStageDrag(e: RawStageMouseEvent): void {
    this.tool?.onStageDrag?.call(this.tool, {
      event: e.evt,
      stage: e.currentTarget,
      canvas: this,
      projectedPoint: this.projectEventLocation(e),
    });
  }

  getStage(target: konva.Node): konva.Stage {
    if (target instanceof konva.Stage) {
      return target;
    } else {
      return this.getStage(target.parent!);
    }
  }

  handleShapeDrag(shape: CanvasShape<any>, e: RawShapeMouseEvent): void {
    this.tool?.onShapeDrag?.call(this.tool, {
      event: e.evt,
      target: e.target,
      stage: this.getStage(e.target),
      shape: shape,
      canvas: this,
    });
  }

  handleShapeClick(shape: CanvasShape<any>, e: RawShapeMouseEvent): void {
    // console.log(this.$parent.$parent?.selectTool('pointer'))
    // this.$nextTick(() => )
    this.tool?.onShapeClick?.call(this.tool, {
      event: e.evt,
      target: e.target,
      stage: this.getStage(e.target),
      shape: shape,
      canvas: this,
    });
  }

  clipFunc(
    ctx: Context,
    points: number[],
    bounded: boolean,
    absolute: boolean
  ): void {
    var offsetX = absolute ? 0 : this.background.x;
    var offsetY = absolute ? 0 : this.background.y;

    if (!bounded) {
      ctx.beginPath();
      ctx.moveTo(this.background.x - offsetX, this.background.y - offsetY);
      ctx.lineTo(
        this.background.width + this.background.x - offsetX,
        this.background.y - offsetY
      );
      ctx.lineTo(
        this.background.width + this.background.x - offsetX,
        this.background.height + this.background.y - offsetY
      );
      ctx.lineTo(
        this.background.x - offsetX,
        this.background.height + this.background.y - offsetY
      );
      ctx.lineTo(this.background.x - offsetX, this.background.y - offsetY);
    }

    ctx.moveTo(points[0] - offsetX, points[1] - offsetY);
    for (let i = 2; i < points.length; i += 2) {
      ctx.lineTo(points[i] - offsetX, points[i + 1] - offsetY);
    }
    ctx.lineTo(points[0] - offsetX, points[1] - offsetY);
  }
}
